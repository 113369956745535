import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  NavigationStart,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  ActivatedRoute,
  NavigationEnd,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";

// Configurations
import {
  cmsToLocalLanguageConfigurations,
  localToCmsLanguageConfigurations,
  countryLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { DeepLinksService } from "src/app/modules/shared/services/deep-links.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Highroller";
  langCode: string;
  showBanner: boolean = false;
  activePath: string;
  gameData: any;
  isGameWindowMinimized: boolean = false;
  isNavpushopen: boolean = false;
  windowType: string;
  routerSub: Subscription;
  isGameWindowLanuchSub: Subscription;
  isGameWindowMinimizedSub: Subscription;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  sessionTimeText: any;
  activePage: string;
  sessionTimeUpdateSub: Subscription;
  isMaintanceOrCountryBlockEnabled: boolean = false;
  activePageSub: Subscription;
  isHeaderHide: boolean = false;
  unsubscribeEmail;
  doLogOutSub: Subscription;
  activeBonusSub: any;
  dropBonusSub: any;

  isQuickDepositOpen = false;
  quickDepositToggleSubs: Subscription;
  openRewardPopup: boolean;
  termsConditionSub: Subscription;
  activeAccountViewSub: Subscription;
  userCountry: string;
  sessionExpiryPopup: boolean = false;
  UserFlowTypes: typeof UserFlowTypes;
  userJurisdiction: string = "";
  userJurisdictionSub: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  cashBackDepositOfferPopup: boolean = false;
  cashBackReward;
  cashBackStartedPopup: boolean = false;
  cashBackUserSlabInfo;
  currencyCodeSub: Subscription;
  currencyCode: string;
  promoConfigInfo;
  cbPromoStartDate;
  cbPromoEndDate;
  cbStartTime;
  cbEndTime;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  @HostListener("window:unload", ["$event"]) unloadHandler(event: Event) {
    if (this.paynplayCashierService.getUserLoggedTime()) {
      localStorage.setItem(
        "loggedInTime",
        this.paynplayCashierService.getUserLoggedTime()
      );
    }
  }

  @HostListener("window:load", ["$event"]) domContentLoadHandler(event: Event) {
    if (localStorage.getItem("loggedInTime")) {
      this.paynplayCashierService.setUserLoggedTime(
        new Date(Date.parse(localStorage.getItem("loggedInTime")))
      );
    }
  }

  constructor(
    private gamePlayService: GamePlayService,
    private translationService: TranslationService,
    private regestrationService: RegistrationService,
    private commonService: CommonService,
    private router: Router,
    private utility: UtilityService,
    private emitterService: EmitterService,
    private gtmService: GtmService,
    private liveChatService: LiveChatService,
    private userDetailsService: UserDetailsService,
    private activatedRoute: ActivatedRoute,
    private customBannerService: CustomBannerService,
    private gameGroupsService: GameGroupsService,
    private paynplayCashierService: PayNPlayCashierService,
    private meta: Meta,
    private deepLinkService: DeepLinksService,
    private cashBackPromoService: CashbackPromoService
  ) {
    this.UserFlowTypes = UserFlowTypes;
    this.gameIframeRedirection();
    // this.addPiqJsEncrypterSrcipt();
    // this.liveGames.connect(environment.liveGameUrl, environment.liveGameCasioID);
    // this.liveGames.getNetentLiveGamesFeed();
    this.getWindowType();
    this.translationService.addLangs(environment.langCode);
    this.addGTMScript();
    this.langCode = this.utility.getLangCode();
    this.translationService.langCode$.subscribe((langcode) => {
      this.langCode = langcode;
      if (this.langCode == "ja-jp") {
        this.addTagsForJapan();
      }
      if (this.langCode == "de-eu") {
        this.addNoIndexTagForGerman();
      }
      this.addGTMScript();
    });
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (
        queryParams.redirect &&
        (queryParams.redirect.includes("deposit") ||
          queryParams.redirect.includes("withdraw"))
      ) {
        setTimeout(() => {
          this.paynplayCashierService.broadCastProcessUserTranaction(
            queryParams.redirect
          );
        }, 500);
        this.clearQueryParams();
      }
    });
    this.routerSub = this.router.events.subscribe((event) => {
      /**we clear game play data here when user navigate out from gamepaly window
       to some other route path with click on minimize options  */
      if (event instanceof NavigationStart) {
        if (
          event.url &&
          !event.url.includes(
            "/" + this.translationService.instant("url.game")
          ) &&
          !this.gamePlayService.isGameMinimized
        ) {
          if (!_.isEmpty(this.gamePlayService.getCurrentGameData())) {
            this.gamePlayService.broadCastIsGameLanuch({});
            this.gamePlayService.setGameCalledfrom(undefined);
          }
        }
        if (
          event.url &&
          (event.url.includes("/maintenance") ||
            event.url.includes("/countryblock") ||
            (window.top !== window.self &&
              (event.url.includes(
                "/" + this.translationService.instant("url.terms_conditions")
              ) ||
                event.url.includes(
                  "/" + this.translationService.instant("url.privacy-policy")
                ) ||
                event.url.includes(
                  "/" + this.translationService.instant("url.cookies-policy")
                ))))
        ) {
          this.isMaintanceOrCountryBlockEnabled = true;
        } else {
          this.isMaintanceOrCountryBlockEnabled = false;
        }
        if (
          event.url == "/" + this.langCode + "/unsubscribe" &&
          this.isLoggedIn
        ) {
          this.router.navigate([this.langCode + "/casino"]);
        }
      }

      /**re-intainize all the required properties
       * after every navigation...
       */
      if (event instanceof NavigationEnd) {
        let currentUrlPath = this.utility.getDecodedCurrentPath();
        const tree: UrlTree = router.parseUrl(event.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g ? g.segments : [];
        if (s && s[1] && s[1].path) {
          this.activePage = this.utility.getActiveLobby(s[1].path);
        } else {
          this.activePage = "";
        }
        if (
          currentUrlPath === "" ||
          currentUrlPath === "/" ||
          currentUrlPath.endsWith(this.langCode)
        ) {
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
        this.utility.updateActiveLeftMenu();
        this.onRouteChange(event);
      }
    });

    this.isGameWindowLanuchSub = this.gamePlayService.isGamewindowLanuched$.subscribe(
      (gameData) => {
        this.gameData = Object.keys(gameData).length > 0 ? gameData : undefined;
      }
    );
    this.isGameWindowMinimizedSub = this.gamePlayService.isGameWindowMinimized$.subscribe(
      (flag) => {
        this.isGameWindowMinimized = flag;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        this.setSessionTimeText();
      }
    );
    this.loginCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        if (!_.isEmpty(this.gamePlayService.getCurrentGameData())) {
          this.gamePlayService.clearGameWindowData();
        }
        this.clearServiceDataonLogOut();
        this.paynplayCashierService.setUserLoggedTime("");
        if (window["LiveChatWidget"] && document.getElementById("lc")) {
          this.liveChatService.onUpdateSessionData();
        }
      }
    );
    this.sessionTimeUpdateSub = this.commonService.sessionTimeUpdated$.subscribe(
      (data) => {
        this.setSessionTimeText();
      }
    );

    this.activePageSub = this.commonService.updateActivePage$.subscribe(
      (pagename) => {
        if (pagename === "gamepage") {
          this.isHeaderHide = true;
        } else {
          this.isHeaderHide = false;
        }
      }
    );
    this.doLogOutSub = this.emitterService.doLogOut$.subscribe((flag) => {
      if (flag) {
        this.commonService.doLogout();
      }
    });

    // this.userDetailsService.currencyCodeSb$.subscribe(currencyCode=>{
    //   this.liveGames.getNetentLiveGamesFeed();
    //   this.liveGames.connect(environment.liveGameUrl, environment.liveGameCasioID);
    // });

    this.quickDepositToggleSubs = this.commonService.quickDepositToggle$.subscribe(
      (isQuickDepositOpen) => {
        this.isQuickDepositOpen = isQuickDepositOpen;
      }
    );

    this.termsConditionSub = this.emitterService.openRewardTermsConditions$.subscribe(
      (flag) => {
        this.openRewardPopup = flag;
      }
    );
    this.activeAccountViewSub = this.commonService.activeAccountView$.subscribe(
      (tabname) => {
        if (this.isQuickDepositOpen && this.windowType === "mobile") {
          this.closeQuickDepositBackdrop();
        }
      }
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params["txRefId"]) {
        let txRefId = params["txRefId"];
        this.customBannerService.setIsProfileRefreshRequired(true);
        this.emitterService.broadCastDepositSuccesful(true);
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {},
        });
      }
    });

    this.userCountry = this.utility.getUserCountryCode();
    this.userDetailsService.userProfileUpdated$.subscribe((data) => {
      this.userCountry = this.utility.getUserCountryCode();
    });
    this.commonService.updateCountryCode$.subscribe((data) => {
      this.userCountry = this.utility.getUserCountryCode();
    });
    this.userJurisdiction = this.commonService.getUserJurisdiction();
    this.userJurisdictionSub = this.commonService.userJurisdictionEvent$.subscribe(
      (jurisdiction) => {
        this.userJurisdiction = jurisdiction;
      }
    );

    this.userDetailsService.currencyCodeSb$
      .pipe(takeUntil(this.destroy$))
      .subscribe((curreny) => {
        this.currencyCode = curreny;
      });

    this.cashBackPromoService.cashBackOfferAmount$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cashBackAmount) => {
        if (cashBackAmount) {
          this.cashBackReward = cashBackAmount;
          this.cashBackDepositOfferPopup = true;
          this.utility.addClassToAppBody("overflow-hidden");
        }
      });
    this.cashBackPromoService.cashBackstartedPromoPopup$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.getCBPromoConfigDetails(data);
        }
      });
  }

  receiveMessage(event) {
    alert("Get transaction status" + JSON.parse(event));
  }

  getCBPromoConfigDetails(data) {
    let requestData = {
      promoRaceId: data["promoRaceId"],
      promoParentId: data["promoParentId"],
    };
    this.cashBackPromoService
      .getPromoConfigDetails(requestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS" && data["promoConfig"]) {
          this.promoConfigInfo = data["promoConfig"];
          if (
            this.promoConfigInfo["campaignStartDate"] &&
            this.promoConfigInfo["campaignEndDate"]
          ) {
            this.cbPromoStartDate = new Date(
              this.promoConfigInfo["campaignStartDate"]
            ).toDateString();
            this.cbPromoEndDate = new Date(
              this.promoConfigInfo["campaignEndDate"]
            ).toDateString();
            this.cbStartTime = new Date(
              this.promoConfigInfo["campaignStartDate"]
            ).toLocaleTimeString();
            this.cbEndTime = new Date(
              this.promoConfigInfo["campaignEndDate"]
            ).toLocaleTimeString();
          }
          let slabGroupInfo = this.promoConfigInfo["slabGroupInfo"][
            Object.keys(this.promoConfigInfo["slabGroupInfo"])[0]
          ];
          this.cashBackUserSlabInfo = _.filter(slabGroupInfo["slabInfo"], {
            slabCurrency: this.currencyCode,
          })[0];
          this.cashBackStartedPopup = true;
          this.utility.addClassToAppBody("overflow-hidden");
        }
      });
  }

  ngOnInit() {
    this.regestrationService.initiatedRegistrationCallBacks();
    this.isCountryBlocked();
  }

  closeQuickDepositBackdrop() {
    this.commonService.broadcastQuickDepositToggle(false);
  }

  ipBasedLangCode: string;
  isCountryBlocked() {
    Promise.resolve(this.commonService.getCountryBlockData()).then((data) => {
      if (data && data["countryCode"]) {
        this.commonService.broadcastUpdateCountryCode(data["countryCode"]);
        if (!this.isLoggedIn) {
          this.commonService.setCurrencyByLocality();
        }
        /**Here 'IN' language code is already occupied by indonesia country
         * in our CMS system..so to avoid conflict we are using ei for indain english
         * As configured in CMS.
         * so we are overriding India(IN code) to ei,To manage language translations.
         * & we also overriding Canada(CA code) to cd,To manage language translations.
         */
        this.ipBasedLangCode =
        cmsToLocalLanguageConfigurations[countryLanguageConfigurations[data["countryCode"]]];
        this.setLang(this.ipBasedLangCode);
      }
    });
  }
  doLogout() {
    this.commonService.doLogout();
  }
  navigateTo(navigateTo) {
    switch (navigateTo) {
      case "login":
        this.router.navigate([this.langCode + "/login"]);
        break;
      case "/logout":
        this.doLogout();
        break;
      case "home":
        this.router.navigate([this.langCode + "/casino"]);
      default:
        this.router.navigate([this.langCode + "/casino"]);
    }
  }

  //var regx=/^[a-z]{2}-[a-z]{2}$/

  setLang(ipbasedLang) {
    let regfirst = /^[a-z]{2}-[a-z]{2}$/;
    let regSecond = /^[a-z]{2}-[a-z]{3}$/;
    let locationPath = this.utility.getDecodedCurrentPath();
    let acceptedLanguages = supportedLanguagesList();
    const localLangcode = localStorage.getItem("langCode");
    if (locationPath && locationPath.length > 1) {
      let languageCodeFromURL = this.utility
        .getDecodedCurrentPath()
        .split("/")[1];
      if (
        languageCodeFromURL &&
        (regfirst.test(languageCodeFromURL) ||
          regSecond.test(languageCodeFromURL))
      ) {
        if (
          languageCodeFromURL &&
          _.contains(acceptedLanguages, languageCodeFromURL)
        ) {
          if (this.isLoggedIn && localLangcode) {
            this.userProfileLangNavigation(regfirst, languageCodeFromURL);
          } else {
            this.updateLanguageCode(languageCodeFromURL);
            localStorage.setItem("langCode", languageCodeFromURL);
          }
        } else {
          let pathwithoutlangCode;
          if (regfirst.test(languageCodeFromURL)) {
            pathwithoutlangCode = this.utility
              .getDecodedCurrentPath()
              .substring(6);
          } else {
            pathwithoutlangCode = this.utility
              .getDecodedCurrentPath()
              .substring(7);
          }
          if (localLangcode && _.contains(acceptedLanguages, localLangcode)) {
            this.navigateAfterSetLang(
              localLangcode + "/" + pathwithoutlangCode
            );
            this.langCode = localLangcode;
          } else if (
            ipbasedLang &&
            _.contains(acceptedLanguages, ipbasedLang)
          ) {
            this.updateLanguageCode(ipbasedLang);
            this.navigateAfterSetLang(ipbasedLang + "/" + pathwithoutlangCode);
          } else {
            this.updateLanguageCode(environment.defaultLang);
            this.navigateAfterSetLang(
              environment.defaultLang + "/" + pathwithoutlangCode
            );
          }
        }
      } else {
        if (localLangcode && _.contains(acceptedLanguages, localLangcode)) {
          this.navigateAfterSetLang(localLangcode + "/" + locationPath);
          this.langCode = localLangcode;
        } else if (ipbasedLang && _.contains(acceptedLanguages, ipbasedLang)) {
          this.updateLanguageCode(ipbasedLang);
          this.navigateAfterSetLang(ipbasedLang + "/" + locationPath);
        } else {
          this.updateLanguageCode(environment.defaultLang);
          this.navigateAfterSetLang(
            environment.defaultLang + "/" + locationPath
          );
        }
      }
    } else {
      if (localLangcode && _.contains(acceptedLanguages, localLangcode)) {
        this.navigateAfterSetLang(localLangcode);
        this.langCode = localLangcode;
      } else if (ipbasedLang && _.contains(acceptedLanguages, ipbasedLang)) {
        this.updateLanguageCode(ipbasedLang);
        this.navigateAfterSetLang(ipbasedLang);
      } else {
        this.updateLanguageCode(environment.defaultLang);
        this.navigateAfterSetLang(environment.defaultLang);
      }
    }
    const queryParams = this.utility.getQueryParams();

    if (!_.isEmpty(queryParams)) {
      if (
        queryParams.redirect &&
        (queryParams.redirect.includes("deposit") ||
          queryParams.redirect.includes("withdraw"))
      ) {
        this.utility.openAccountComponent(queryParams.redirect);
      }
      setTimeout(() => {
        this.handleQueryParams(queryParams);
      }, 500);
    }
  }

  updateLanguageCode(languageCode) {
    this.translationService.use(localToCmsLanguageConfigurations[languageCode]);
  }

  navigateAfterSetLang(path) {
    if (window.location.search.length > 0) {
      const params = this.utility.getQueryParams();
      this.router.navigate([path], { queryParams: params });
    } else {
      this.router.navigate([path]);
    }
  }

  userProfileLangNavigation(regfirst, languageCodeFromURL) {
    let pathwithoutlangCode;
    if (regfirst.test(languageCodeFromURL)) {
      pathwithoutlangCode = this.utility.getDecodedCurrentPath().substring(6);
    } else {
      pathwithoutlangCode = this.utility.getDecodedCurrentPath().substring(7);
    }
    this.navigateAfterSetLang(this.langCode + "/" + pathwithoutlangCode);
  }

  getWindowType() {
    var ww = document.body.clientWidth;

    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.checkIsQuickDepsoitOpened();
      this.windowType = "mobile";
    }
  }
  /**This check is needed because we have two set's of UI on mobile(portrait) & device(landscape)
   * which is hiding quick deposit popup when open & rotated from portrait to landscape & landscape to portrait
   * very edge case but handled it.
   */
  checkIsQuickDepsoitOpened() {
    const depositPopDivElem = document.getElementById("qd-3ddeposit-popup");
    if (
      depositPopDivElem &&
      depositPopDivElem.style.getPropertyValue("display") === "block"
    ) {
      this.isQuickDepositOpen = true;
    }
  }

  setSessionTimeText() {
    if (sessionStorage.getItem("session")) {
      let minutes = JSON.parse(sessionStorage.getItem("session"))[
        "sessionLimit"
      ];
      this.sessionTimeText = this.utility.getSessionTimeText(minutes);
    }
  }

  sesssionExpiryLogin() {
    this.sesssionExpiryBackdropClick();
    this.utility.initiateLogin();
  }

  sesssionExpiryBackdropClick() {
    this.sessionExpiryPopup = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  rewardTCBackdropClick() {
    this.utility.toggleRewardTCPopUp(false);
  }

  gameIframeRedirection() {
    const ww = document.body.clientWidth;
    //if (ww <= 1024) {
    let windowParentOrigin = window.parent.location.origin;
    let doc = window.parent.document;
    let iframeId = doc.getElementById("netentgame")
      ? "netentgame"
      : "gamePlayIframe";
    const element: HTMLIFrameElement = doc.getElementById(
      iframeId
    ) as HTMLIFrameElement;
    if (element) {
      const iframe = element.contentDocument || element.contentWindow.document;
      const iframeOrigin = iframe.location.origin;
      if (iframeOrigin === windowParentOrigin) {
        let clsoeEle = window.parent.document.getElementById("closeGame");
        $(clsoeEle).trigger("click");
      }
    }
  }

  addPiqJsEncrypterSrcipt() {
    let piqScript = document.createElement("script");
    piqScript.src =
      environment.paymentIqUrl +
      "/api/viq/jscardencrypter/" +
      environment.paymentIqMID;
    document.body.appendChild(piqScript);
  }

  onRouteChange(event) {
    this.gtmService.routeChangeGtmEvent(event);
  }

  /**
   * DeepLinks Navigation URL:
   * 1. Deposit Deeplink URL
   *   link:{{SiteURL}}/en-row/casino?tab=deposit
   * 2. Deposit Deeplink with active bonus URL:
   *   link:{{SiteURL}}/en-row/casino?tab=deposit&bonusCode=test3reload
   * 3.Rewards Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=rewards
   * 4.KYC Verification Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=confirmidentity
   * 5.Live Chat Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=live_chat
   * 6.Preferences Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=preferences
   * 7.login Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?login=true
   * 8.Registration Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?register=true
   * 9.Game window Deeplink URL:
   *   link:{{SiteURL}}/en-eu/game/7-piggies?login=true
   * 10.Lobby Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?lobby=slots
   * 11.Game Group Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?lobby=slots&group=new-games
   * 12.Promotions Deeplink URL:
   *  a) For all login promotions
   *  link:{{SiteURL}}/en-row/promotions?login=true
   *  b) For all logout Promotions URL:
   *  link:{{SiteURL}}/en-row/promotions
   * 13.Promotion Content Deeplink URL:
   *  a) Promotion Content for loggedIn
   *  link:{{SiteURL}}/en-row/promotions/search-anything-anywhere?login=true
   *  b) Promotion Content for logged out ()
   *  link:{{SiteURL}}/en-row/promotions/search-anything-anywhere
   */

  handleQueryParams(queryParams) {
    if (!this.isLoggedIn && queryParams["register"] === "true") {
      this.initiateRegistration();
    } else if (!this.isLoggedIn && queryParams["login"] === "true") {
      let path = this.utility.getDecodedCurrentPath();
      if (
        path &&
        path.includes(this.translationService.instant("url.promotions")) &&
        path.split("/").length > 3
      ) {
        this.deepLinkService.navigatePromotionQueryParams(path);
      }
      if (path && path.includes(this.translationService.instant("url.game"))) {
        this.deepLinkService.navigateGamePlayWindowQP(path);
      }
      this.utility.initiateLogin();
    } else if (queryParams["tab"] === "live_chat") {
      this.liveChatService.onInitializeChat();
    } else if (queryParams["tab"] === "confirmidentity" && this.isLoggedIn) {
      this.deepLinkService.navigateKycLevelQueryParams(queryParams);
    } else if (this.isLoggedIn && queryParams.hasOwnProperty("tab")) {
      this.deepLinkService.navigateLoggedInQP(queryParams);
    } else if (!this.isLoggedIn && queryParams.hasOwnProperty("tab")) {
      this.deepLinkService.navigateLoggoutQP(queryParams);
    } else if (queryParams && queryParams.hasOwnProperty("lobby")) {
      this.deepLinkService.navigateLobbyGameGroupsQuery(queryParams);
    }
    setTimeout(() => {
      if (window.location.pathname.split("/")[2] !== "reset-password") {
        this.utility.removeUrlQueryParams();
      }
    }, 3000);
  }

  addTagsForJapan() {
    document.querySelector("html").setAttribute("translate", "no");
    this.meta.addTag({ name: "google", content: "notranslate" });
    this.meta.addTag({ "http-Equiv": "Content-Language", content: "ja" });
  }

  addNoIndexTagForGerman() {
    this.meta.addTag({ name: "robots", content: "noindex" });
  }

  clearServiceDataonLogOut(): void {
    if (!_.isEmpty(this.gamePlayService.getCurrentGameData())) {
      this.gamePlayService.clearGameWindowData();
    }

    this.commonService.broadCastUpdateActiveLobby(null);

    this.gameGroupsService.broadCastIsLastPlayedLiveGamesExist(false);
  }

  closePromotionalVideo() {
    // this.utility.removeClassFromAppBody('overflow-hidden');
    this.emitterService.broadCastEnablePromotionVideo(false);
  }

  initiateRegistration() {
    if (this.utility.isPnpFlow()) {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "deepLink",
      });
    } else {
      this.regestrationService.openRegistrationHanlder();
    }
  }

  closeConfirmDialogue() {
    this.cashBackDepositOfferPopup = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  clearQueryParams() {
    var snapshot = this.activatedRoute.snapshot;
    const params = { ...snapshot.queryParams };
    delete params.redirect;
    this.router.navigate([], { queryParams: params });
  }

  /**
   * We add GTM script based on Market zimplerpnp market
   * and classic casino market dynamically
   *
   **/
  addGTMScript() {
    //  window['dataLayer'] = window['dataLayer'] || [];
    let trackingId = this.utility.isPnpFlow()
      ? environment.pnpGTM_trackingId
      : environment.classicGTM_trackingId;
    this.setGtmTagManager(window, document, "script", "dataLayer", trackingId);
    this.appendNoScript(trackingId);
  }

  appendNoScript(trackingId) {
    let noscriptiframe: any = document.querySelector("noscript iframe");
    if (
      noscriptiframe &&
      noscriptiframe.src.includes("https://www.googletagmanager.com/ns")
    ) {
      noscriptiframe.src =
        "https://www.googletagmanager.com/ns.html?id=" + trackingId;
    } else {
      let noscript = document.createElement("noscript");
      let iframe = document.createElement("iframe");
      iframe.src = "https://www.googletagmanager.com/ns.html?id=" + trackingId;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";
      noscript.appendChild(iframe);
      document.body.appendChild(noscript);
    }
  }

  setGtmTagManager(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var dl = l != "dataLayer" ? "&l=" + l : "";
    let script: any = document.querySelector("script");
    if (script && script.src.includes("https://www.googletagmanager.com/gtm")) {
      script.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    } else {
      var f = d.getElementsByTagName(s)[0];
      var j: any = d.createElement(s);
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    }
  }

  closePopup() {
    this.cashBackStartedPopup = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
    this.isGameWindowLanuchSub.unsubscribe();
    this.isGameWindowMinimizedSub.unsubscribe();
    this.sessionTimeUpdateSub.unsubscribe();
    this.activePageSub.unsubscribe();
    this.doLogOutSub.unsubscribe();
    this.quickDepositToggleSubs.unsubscribe();
    this.termsConditionSub.unsubscribe();
    this.activeAccountViewSub.unsubscribe();
    this.userJurisdictionSub.unsubscribe();
    if (this.activeBonusSub) {
      this.activeBonusSub.unsubscribe();
    }
    if (this.dropBonusSub) {
      this.dropBonusSub.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

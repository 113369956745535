import { map, catchError, pairwise, filter, take, tap } from "rxjs/operators";
import { Router, RoutesRecognized } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject, Subscription, Observable, throwError, Subject, of } from "rxjs";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";
import {
  marketLocaleCurrencyMappingConfigurations,
  seoAlternativeLanguagesConfigurations,
  seoLanguageCodeUpdateConfigurations,
  localToCmsLanguageConfigurations,
  hrefLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { NavigationAfterLogin } from "src/app/modules/shared/models/navigation/navigate-after-login.model";
import { NavigationData } from "src/app/modules/shared/models/navigation/navigation.model";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { SocketService } from "src/app/modules/shared/services/socket.service";
import { MainService } from "src/app/modules/shared/services/main.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { CountryBlockType } from "src/app/modules/shared/models/country/country-block-type.model";
import { URLS_TRANSLATIONS } from "src/app/modules/shared/constants/urls.constant";
import { UrlLanguagesTranslations } from "src/app/modules/shared/models/urls/url-languages.model";

@Injectable()
export class CommonService {
  // Strings
  previousComponentURL: string = "";
  activeAccountTab: string = "";
  userJurisdiction: string = "";
  activeLobbyName: string = "";
  previousURL: string = "";
  langCode: string = "";

  // Booleans
  isUserVisitedLandingPage: boolean = false;
  isKycBlocked: boolean = true;
  get isCountryBlockedSoft(): boolean {
    return this.countryBlockType === CountryBlockType.Soft;
  }

  // Arrays
  profileBalanceCurrencyCallQueue = [];
  userBalanceByPocketsCallQueue = [];
  countryBlockCallQueue = [];

  // To Discover
  navigateAfterLogin: NavigationAfterLogin = {};
  profileCountryDetails: any;
  activeAccountViewInfo: any;
  sessionLimitInterval: any;
  activeDepositBonus: any;
  public socketDetails;

  // Others
  kycDetailsToUnblock: {
    address: boolean;
    identity: boolean;
    paymentInstrument: boolean;
    sourceOfIncome: boolean;
    enableKyc: boolean;
  } = {
    address: false,
    identity: false,
    paymentInstrument: false,
    sourceOfIncome: false,
    enableKyc: false,
  };
  countryBlockType: CountryBlockType;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private userDetailService: UserDetailsService,
    @Inject(DOCUMENT) private document: Document,
    private socketService: SocketService,
    private mainService: MainService,
    private router: Router
  ) {
    this.subscriptions = [
      this.translationService.langCodeSb$.subscribe((languageCode: string) => {
        this.langCode = languageCode;
      }),
      this.loginComplete$.subscribe(() => {
        Promise.all([this.getUserBalanceByPockets(), this.getProfileBalanceCurrency()]).then();
      }),
      this.logOutComplete$.subscribe(() => {
        this.kycDetailsToUnblock = {
          address: false,
          identity: false,
          paymentInstrument: false,
          sourceOfIncome: false,
          enableKyc: false,
        };
      }),
      /* 
      Game window back navigation handling related code
      */
      this.router.events
        .pipe(
          filter((evt: RoutesRecognized) => evt instanceof RoutesRecognized),
          pairwise()
        )
        .subscribe((events: RoutesRecognized[]) => {
          let urlAfterRedirect: string = decodeURIComponent(events[0].urlAfterRedirects);

          let temp: string[] = this.getDecodedCurrentPath().split("/");

          let translatedString: string = this.translationService.instant("url.game").toLowerCase();

          if (temp.length > 2 && temp[2] != translatedString) {
            this.previousComponentURL = urlAfterRedirect;

            if (temp[2] != "studio") {
              this.previousURL = this.previousComponentURL;
            }
          } else if (temp.length == 2) {
            this.previousComponentURL = undefined;

            this.previousURL = undefined;
          }
        }),
    ];
  }

  /*
  Triggers after succesfull login
  */
  public loginComplete: Subject<boolean> = new Subject<boolean>();
  public loginComplete$: Observable<boolean> = this.loginComplete.asObservable();

  broadCastLoginSuccess(islogin: boolean): void {
    this.loginComplete.next(islogin);
  }

  /*
  Triggers after succesfull logout
  */
  public logOutComplete: Subject<boolean> = new Subject<boolean>();
  public logOutComplete$: Observable<boolean> = this.logOutComplete.asObservable();

  broadCastIsLoggedOut(islogin: boolean): void {
    this.logOutComplete.next(islogin);
  }

  /*
    Information related to socket is stored her
    we use this data in socket service ts file
  */
  setSocketDetails(socketInfo): void {
    this.socketDetails = socketInfo;
  }

  getSocketDetails() {
    return this.socketDetails;
  }

  private activeAccountView: Subject<string> = new Subject<string>();
  public activeAccountView$: Observable<string> = this.activeAccountView.asObservable();

  /*
    left menu active tab related subject Observable
  */
  private activeLeftMenuLobby: BehaviorSubject<string> = new BehaviorSubject<string>("casino");
  public activeLeftMenuLobby$: Observable<string> = this.activeLeftMenuLobby.asObservable();

  broadCastActiveLeftMenuLobby(name: string): void {
    this.activeLeftMenuLobby.next(name);
  }

  private activeLeftMenu: Subject<string> = new Subject<string>();
  public activeLeftMenu$: Observable<string> = this.activeLeftMenu.asObservable();

  broadCastActiveLeftMenu(name: string): void {
    this.activeLeftMenu.next(name);
  }

  setActiveLobby(name: string): void {
    this.activeLobbyName = name;
  }

  getActiveLobby(): string {
    return this.activeLobbyName;
  }

  public updateActiveLobbyData: Subject<NavigationData> = new Subject<NavigationData>();
  public updateActiveLobbyData$: Observable<NavigationData> = this.updateActiveLobbyData.asObservable();

  broadCastUpdateActiveLobby(data?: NavigationData): void {
    if (data && data.lobby) {
      this.activeLobbyName = data.lobby;
    }

    this.updateActiveLobbyData.next(data);
  }

  setNavigateAfterLogin(navigateAfterLogin: NavigationAfterLogin): void {
    this.navigateAfterLogin = navigateAfterLogin;
  }

  getNavigateAfterLogin(): NavigationAfterLogin {
    return this.navigateAfterLogin;
  }

  private toggleAccountMenu: Subject<boolean> = new Subject<boolean>();
  public toggleAccountMenu$: Observable<boolean> = this.toggleAccountMenu.asObservable();

  private toggleComponentWrap: Subject<boolean> = new Subject<boolean>();
  public toggleComponentWrap$: Observable<boolean> = this.toggleComponentWrap.asObservable();

  private sessionTimeUpdated: Subject<boolean> = new Subject<boolean>();
  public sessionTimeUpdated$: Observable<boolean> = this.sessionTimeUpdated.asObservable();

  broadCastSessionUpdated(flag: boolean): void {
    this.sessionTimeUpdated.next(flag);
  }

  broadCastAccountMenuStatus(flag: boolean): void {
    this.toggleAccountMenu.next(flag);
  }

  broadCastComponentWrapStatus(flag: boolean): void {
    this.toggleComponentWrap.next(flag);
  }

  broadCastActiveAcountMenu(tab: string): void {
    this.setActiveAccountMenu(tab);

    this.activeAccountView.next(tab);
  }

  setActiveAccountMenu(activeMenu: string): void {
    this.activeAccountTab = activeMenu;
  }

  getActiveAccountMenu(): string {
    return this.activeAccountTab;
  }

  private userAccountKycStatus = new Subject<boolean>();
  public userAccountKycStatus$ = this.userAccountKycStatus.asObservable();

  broadCastAccountKycStatus(kycData): void {
    this.userAccountKycStatus.next(kycData);
  }

  setUserAccountKycStatus(kycData): void {
    this.userAccountKycStatus = kycData;
  }

  setActiveDepositBonus(depositBonusId): void {
    this.activeDepositBonus = depositBonusId;
  }

  getActiveDepositBonus() {
    return this.activeDepositBonus;
  }

  private gameWindowRegilyPopUpTimerUpdate: Subject<boolean> = new Subject<boolean>();
  public gameWindowRegilyPopUpTimerUpdate$: Observable<boolean> = this.gameWindowRegilyPopUpTimerUpdate.asObservable();

  broadCastGameWindowRegilyPopUpTimer(): void {
    this.gameWindowRegilyPopUpTimerUpdate.next(true);
  }

  private updateActivePage: Subject<string> = new Subject<string>();
  public updateActivePage$: Observable<string> = this.updateActivePage.asObservable();

  broadCastActivePage(pageName: string): void {
    this.updateActivePage.next(pageName);
  }

  setIsUservisitedLandingPage(flag: boolean): void {
    this.isUserVisitedLandingPage = flag;
  }

  getIsUserVisitedLangingPage(): boolean {
    return this.isUserVisitedLandingPage;
  }

  /**ApI calls....*/

  validateUniqueness(fieldToValidate): Observable<any> {
    let url: string = "";

    _.each(fieldToValidate, function (value, key) {
      url = key == "txtNickname" ? "/ajax/registration/isUniqueNickname" : "/ajax/registration/isUniqueEmail";
    });

    return this.mainService.psPost(url, fieldToValidate);
  }

  getCountryBlockData(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this.countryBlockCallQueue.length > 0) {
        this.countryBlockCallQueue.push(resolve);
      } else {
        this.countryBlockCallQueue.push(resolve);

        Promise.resolve(this.mainService.getCountryBlockData()).then((data) => {
          for (let callback in this.countryBlockCallQueue) {
            this.countryBlockCallQueue[callback](data);
          }

          this.countryBlockCallQueue = [];
        });
      }
    });
  }

  getUserBalanceByPockets(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this.userBalanceByPocketsCallQueue.length > 0) {
        this.userBalanceByPocketsCallQueue.push(resolve);
      } else {
        this.userBalanceByPocketsCallQueue.push(resolve);

        Promise.resolve(this.mainService.getUserBalanceByPockets())
          .then((balancePockets) => {
            if (balancePockets) {
              this.userDetailService.setUserBalanceDetails(balancePockets);
            }

            for (let callback in this.userBalanceByPocketsCallQueue) {
              this.userBalanceByPocketsCallQueue[callback](balancePockets);
            }

            this.userBalanceByPocketsCallQueue = [];

            return balancePockets;
          })
          .catch((error) => {
            this.userBalanceByPocketsCallQueue = [];

            return {};
          });
      }
    });
  }

  getProfileBalanceCurrency(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this.profileBalanceCurrencyCallQueue.length > 0) {
        this.profileBalanceCurrencyCallQueue.push(resolve);
      } else {
        this.profileBalanceCurrencyCallQueue.push(resolve);
        Promise.resolve(this.mainService.getProfileBalanceCurrency())
          .then((profileBalanceCurrency) => {
            if (profileBalanceCurrency && profileBalanceCurrency["profile"] && profileBalanceCurrency["currency"]) {
              // this.userDetailService.setUserBalanceDetails(profileBalanceCurrency['profile']['balanceDetails']);
              this.setUserJurisdiction(profileBalanceCurrency["profile"]["jurisdiction"]);
              this.userDetailService.setCurrencyCode(profileBalanceCurrency["currency"].code);
              this.userDetailService.setCurrencySymbol(profileBalanceCurrency["currency"].symbol);
              this.userDetailService.setUserCurrencyDetails(profileBalanceCurrency["currency"]);
              this.userDetailService.setUserProfileDetails(profileBalanceCurrency["profile"]);
              sessionStorage.setItem("_player", btoa(profileBalanceCurrency["profile"]["playerID"]));
            }
            for (let callback in this.profileBalanceCurrencyCallQueue) {
              this.profileBalanceCurrencyCallQueue[callback](profileBalanceCurrency);
            }
            this.profileBalanceCurrencyCallQueue = [];
            return profileBalanceCurrency;
          })
          .catch((error) => {
            this.profileBalanceCurrencyCallQueue = [];
            return {};
          });
      }
    });
  }

  getUserData(): Observable<UserData> {
    return this.mainService.getUserData().pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getUserDataCacheFirst(): Observable<UserData> {
    const userProfile = this.userDetailService.getUserProfileDetails();
    if (userProfile) {
      return of(userProfile);
    } else {
      return this.getUserData().pipe(
        take(1),
        tap((userData: UserData) => {
          this.userDetailService.setUserProfileDetails(userData);
        })
      );
    }
  }

  doProfileUpdate(requestObj): Observable<any> {
    return this.mainService.doProfileUpdate(requestObj).pipe(
      map((response) => {
        if (response) {
          this.userDetailService.setUserProfileDetails(response);
        }
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getProfileCountryDetails(): Observable<any> {
    if (!_.isEmpty(this.profileCountryDetails)) {
      return of(this.profileCountryDetails);
    } else {
      return this.mainService.getProfileCountryDetails().pipe(
        map((response) => {
          this.profileCountryDetails = response;
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }
  getProfileData(): Observable<any> {
    return this.mainService.psGet(endpointConfigurations.getData_url).pipe(
      map((profileDataResponse) => {
        if (profileDataResponse && !_.isEmpty(profileDataResponse)) {
          this.userDetailService.setUserProfileDetails(profileDataResponse);
          sessionStorage.setItem("_player", btoa(profileDataResponse["playerID"]));
        }
        return profileDataResponse;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  forceGetUserProfileDetails(): Observable<any> {
    return this.mainService.psGet(endpointConfigurations.getBalance_url).pipe(
      map((balanceDetails) => {
        if (balanceDetails) {
          this.userDetailService.setUserBalanceDetails(balanceDetails);
        }
        return balanceDetails;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  /**Here we will logout user..
   * even Logout api fails by removing active user &
   * active session from sessionStorage
   * */

  doLogout() {
    if (this.getActiveAccountMenu()) {
      this.broadCastActiveAcountMenu("");
    }
    localStorage.removeItem("hir_user");
    sessionStorage.removeItem("session");
    sessionStorage.removeItem("_player");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("loggedInTime");
    this.userDetailService.resetAllDetails();

    this.mainService.psPost(endpointConfigurations.dologout_url, {}).subscribe((data) => {
      this.router.navigate([this.langCode + "/casino"]);
      this.setCurrencyByLocality();
      this.broadCastIsLoggedOut(true);
    });

    this.setUserJurisdiction("");
  }

  loginStatusCallQueue = [];
  getLoginStatus() {
    return new Promise((resolve, reject) => {
      if (this.loginStatusCallQueue.length > 0) {
        this.loginStatusCallQueue.push(resolve);
      } else {
        this.loginStatusCallQueue.push(resolve);
        Promise.resolve(this.mainService.getLoginStatus())
          .then((data) => {
            if (data && data["status"] === true) {
              this.socketService.connectToScoket(data["pragmaticUrl"], data["pragmaticSessionId"], true);
              localStorage.setItem("hir_user", "true");
              this.broadCastLoginSuccess(true);
              //this double check is need for one different case.(& due to deploy in response)
              this.checkIsLandingPage();
              this.setSocketDetails({
                message: "",
                pragmaticSessionId: data["pragmaticSessionId"],
                socketUrl: data["pragmaticUrl"],
                partnerID: data["partnerId"],
              });
            } else {
              localStorage.removeItem("hir_user");
              sessionStorage.removeItem("session");
            }
            for (let callback in this.loginStatusCallQueue) {
              this.loginStatusCallQueue[callback](data);
            }
            this.loginStatusCallQueue = [];
          })
          .catch((error) => {
            return [];
          });
      }
    });
  }

  checkIsLandingPage() {
    const path = this.getDecodedCurrentPath();
    let params = {};
    if (window.location.search) {
      params = JSON.parse(
        '{"' + decodeURI(window.location.search.substring(1).replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
      );
    }
    const regfirst = /^[a-z]{2}-[a-z]{2}$/;
    const regSecond = /^[a-z]{2}-[a-z]{3}$/;
    let segments = path.split("/");
    let languageCodeFromURL = this.getDecodedCurrentPath().split("/")[1];
    if (
      segments &&
      segments.length <= 2 &&
      (regfirst.test(languageCodeFromURL) || regSecond.test(languageCodeFromURL))
    ) {
      if (sessionStorage.getItem("isUserVistedLandingPage")) {
        if (params) {
          this.router.navigate([path], { queryParams: params });
        } else {
          this.router.navigate([path]);
        }
      }
    }
  }

  setSessionVariable(sessionKey, sessionObj) {
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionObj));
  }

  sessionContinue() {
    let session = JSON.parse(sessionStorage.getItem("session"));
    if (session && session["sessionLimit"]) {
      this.setSessionVariable("session", {
        sessionLimit: session["sessionLimit"],
        availableSessionLimit: session["sessionLimit"] * 60,
      });
      this.startLoginSession();
    } else {
      this.mainService.getResponsibleGamingLimits("session").subscribe((data) => {
        if (data && data["limits"] && data["limits"]["overall"]) {
          let limits = data["limits"]["overall"]["value"];
          this.setSessionVariable("session", {
            sessionLimit: limits,
            availableSessionLimit: limits * 60,
          });
          this.startLoginSession();
        }
      });
    }
  }

  startLoginSession() {
    var self = this;
    this.broadCastSessionUpdated(true);
    if (!this.sessionLimitInterval) {
      this.sessionLimitInterval = setInterval(() => {
        let sessionLimit = JSON.parse(sessionStorage.getItem("session"));
        if (sessionLimit) {
          if (sessionLimit.availableSessionLimit - 1 > 0) {
            sessionLimit.availableSessionLimit = sessionLimit.availableSessionLimit - 1;
            sessionStorage.setItem("session", JSON.stringify(sessionLimit));
          } else {
            clearInterval(self.sessionLimitInterval);
            this.sessionLimitInterval = undefined;
            setTimeout(() => {
              document.getElementById("sessionPopup").style.display = "flex";
              document.body.classList.add("overflow-hidden");
            }, 100);
          }
        } else {
          clearInterval(self.sessionLimitInterval);
          this.sessionLimitInterval = undefined;
        }
      }, 1000);
    }
  }

  private quickDepositToggle = new Subject<boolean>();
  public quickDepositToggle$ = this.quickDepositToggle.asObservable();

  broadcastQuickDepositToggle(flag: boolean): void {
    this.quickDepositToggle.next(flag);
  }

  private pendingWithdrawCancel = new Subject<boolean>();
  public pendingWithdrawCancel$ = this.pendingWithdrawCancel.asObservable();

  broadcastPendingWithdrawCancel(flag) {
    this.pendingWithdrawCancel.next(flag);
  }

  getUserKycLevelDetails(): Observable<any> {
    return this.mainService.getUserKycLevelDetails().pipe(
      map((useKycDetails) => {
        if (useKycDetails && useKycDetails.status === "SUCCESS") {
          let requiredDocs = useKycDetails["requiredDocs"];
          if (requiredDocs && requiredDocs.length > 0) {
            requiredDocs.filter((doc) => {
              if (useKycDetails["kycDetails"][doc]) {
                this.kycDetailsToUnblock[doc] = "vrfn_new"; // useKycDetails["kycDetails"][doc]["verificationStatus"];
                this.kycDetailsToUnblock.enableKyc = true;
              }
            });
          }
        }
        return this.kycDetailsToUnblock;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getZendeskToken(): Observable<any> {
    return this.mainService.getZendeskToken().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  setCanonicalURL(
    multilingualUrlPath:
      | UrlLanguagesTranslations
      | {
          [key: string]: string;
        }
      | string
  ): void {
    let urlPath: string = "";

    if (typeof multilingualUrlPath === "string") {
      /**we don't have scope for some url in CMS, so handling it from front end
       */
      switch (multilingualUrlPath) {
        case "homepage":
          multilingualUrlPath = URLS_TRANSLATIONS.homepage;
          break;
        case "liveCasinoPage":
          multilingualUrlPath = URLS_TRANSLATIONS.liveCasino;
          break;
        case "promotionspage":
          multilingualUrlPath = URLS_TRANSLATIONS.promotions;
          break;
        case "landingpage":
          multilingualUrlPath = URLS_TRANSLATIONS.landingPage;
          break;
        case "studiopage":
          let path: string = this.getDecodedCurrentPath().replace(`/${this.langCode}/`, "");

          multilingualUrlPath = {
            en: path,
            ja: path,
            fi: path,
            cd: path,
            nl: path,
            nz: path,
            no: path,
            at: path,
            ie: path,
            hu: path,
            et: path,
          };
          break;
      }
    }

    if (multilingualUrlPath && Object.keys(multilingualUrlPath).length > 0) {
      urlPath = multilingualUrlPath[localToCmsLanguageConfigurations[this.langCode]];
      this.setCanonicalLink(urlPath);
      this.setAlternateLinks(multilingualUrlPath);
      this.setDefaultLink(multilingualUrlPath);
      document.querySelector("html").setAttribute("lang", seoLanguageCodeUpdateConfigurations[this.langCode]);
    }
  }

  setCanonicalLink(urlPath) {
    var canonicalLink: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
    if (!canonicalLink) {
      const link: HTMLLinkElement = this.document.createElement("link");
      link.setAttribute("rel", "canonical");
      this.document.head.appendChild(link);
      link.setAttribute("href", environment.siteUrl + "/" + this.langCode + "/" + urlPath);
      link.setAttribute("hreflang", hrefLanguageConfigurations[this.langCode]);
    } else {
      canonicalLink.setAttribute("rel", "canonical");
      canonicalLink.setAttribute("href", environment.siteUrl + "/" + this.langCode + "/" + urlPath);
      canonicalLink.setAttribute("hreflang", hrefLanguageConfigurations[this.langCode]);
    }
  }

  setAlternateLinks(multilingualUrlPath) {
    var alternatelink = this.document.querySelectorAll(`link[rel='alternate']`);
    if (alternatelink.length > 0) {
      alternatelink.forEach((removeExistedAlternateLink) => removeExistedAlternateLink.remove());
    }
    seoAlternativeLanguagesConfigurations.forEach((lang) => {
      const link: HTMLLinkElement = this.document.createElement("link");
      let urlPath = multilingualUrlPath[localToCmsLanguageConfigurations[lang]];
      link.setAttribute("rel", "alternate");
      this.document.head.appendChild(link);
      link.setAttribute("href", environment.siteUrl + "/" + lang + "/" + urlPath);
      link.setAttribute("hreflang", hrefLanguageConfigurations[lang]);
    });
  }

  setDefaultLink(multilingualUrlPath) {
    const linkDefault: HTMLLinkElement = this.document.createElement("link");
    let urlPath = multilingualUrlPath[localToCmsLanguageConfigurations[environment.defaultLang]];
    linkDefault.setAttribute("rel", "alternate");
    this.document.head.appendChild(linkDefault);
    linkDefault.setAttribute("href", environment.siteUrl + "/" + environment.defaultLang + "/" + urlPath);
    linkDefault.setAttribute("hreflang", "x-default");
  }

  countryCode: string;
  private updateCountryCode = new Subject<boolean>();
  public updateCountryCode$ = this.updateCountryCode.asObservable();

  broadcastUpdateCountryCode(countryCode) {
    this.setCountryCode(countryCode);
    this.updateCountryCode.next(countryCode);
  }

  setCountryCode(countryCode: string): void {
    this.countryCode = countryCode;
  }

  getCountryCode(): string {
    return this.countryCode;
  }

  setCurrencyByLocality() {
    let languageCodeFromURL = this.getDecodedCurrentPath().split("/")[1];
    if (
      languageCodeFromURL &&
      languageCodeFromURL !== this.langCode &&
      _.contains(supportedLanguagesList(), languageCodeFromURL)
    ) {
      this.langCode = languageCodeFromURL;
    }
    if (
      this.langCode &&
      marketLocaleCurrencyMappingConfigurations &&
      marketLocaleCurrencyMappingConfigurations.hasOwnProperty(this.langCode)
    ) {
      let marketLocaleCurrencyMappingConfigClone = {
        ...marketLocaleCurrencyMappingConfigurations,
      };
      this.userDetailService.setCurrencySymbol(marketLocaleCurrencyMappingConfigClone[this.langCode]["currencySymbol"]);
      this.userDetailService.setCurrencyCode(marketLocaleCurrencyMappingConfigClone[this.langCode]["currencyCode"]);
    } else {
      this.userDetailService.setCurrencySymbol(environment.defaultCurrencySymbol);
      this.userDetailService.setCurrencyCode(environment.defaultCurrencyCode);
    }
  }

  getZendeskRubikoDetails(userName): Observable<any> {
    return this.mainService.getZendeskRubikoDetails(userName).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public gameplayFooterHover = new BehaviorSubject<Boolean>(false);
  public gameplayFooterHover$: Observable<any> = this.gameplayFooterHover.asObservable();
  public isGamesToasterOpen = new BehaviorSubject<Boolean>(false);
  public isGamesToasterOpen$: Observable<any> = this.isGamesToasterOpen.asObservable();
  public closeGame = new BehaviorSubject<Boolean>(false);
  public closeGame$: Observable<any> = this.closeGame.asObservable();
  getDecodedCurrentPath() {
    return decodeURIComponent(window.location.pathname);
  }

  private isLiveCasinoPage = new BehaviorSubject<any>(false);
  public isLiveCasinoPage$ = this.isLiveCasinoPage.asObservable();

  broadCastIsLiveCasinoPage(flag: boolean) {
    this.isLiveCasinoPage.next(flag);
  }

  updateUserCredentials(requestData) {
    return this.mainService.updateUserCredentails(requestData);
  }

  setUserJurisdiction(jurisdiction) {
    this.userJurisdiction = jurisdiction;
    this.broadCastuserJurisdictionEvent(this.userJurisdiction);
  }

  getUserJurisdiction() {
    return this.userJurisdiction;
  }

  private userJurisdictionEvent = new Subject<any>();
  public userJurisdictionEvent$ = this.userJurisdictionEvent.asObservable();

  broadCastuserJurisdictionEvent(jurisdiction) {
    this.userJurisdictionEvent.next(jurisdiction);
  }

  getUserCountry() {
    if (this.userDetailService.getUserCountryCode()) {
      return this.userDetailService.getUserCountryCode();
    } else {
      this.getProfileBalanceCurrency().then((data) => {
        return data["profile"]["country"];
      });
    }
  }

  setCountryBlockType(blockType: CountryBlockType): void {
    this.countryBlockType = blockType;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}

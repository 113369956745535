// livespins not showing for the following markets
export const livespinsExcludedMarketsConfigurations: string[] = [
  "ja-jp",
  "en-eu",
];

export const languageCodeToISO6393Configurations: {
  [key: string]: string[];
} = {
  "en-row": ["eng"],
  "en-ca": ["eng"],
  "en-nz": ["eng"],
  "en-eu": ["eng"],
  "fi-fi": ["eng", "fin"],
  "de-de": ["eng", "deu"],
  "nb-no": ["eng", "nor", "nob", "nno"],
  "hu-hu": ["eng", "huh"],
  "et-ee": ["eng", "est"],
};

export const languageCodeConfigurations: {
  [key: string]: string[];
} = {
  en: ["eng", "en"],
  fi: ["eng", "en", "fin", "fi"],
  de: ["eng", "en", "deu", "de"],
  nb: ["eng", "en", "nor", "nob", "nno", "no"],
  hu: ["eng", "en"],
  et: ["eng", "en", "est", "et"],
};

export const livespinsLanguagesConfigurations = (
  languageCode: string
): string[] => {
  return languageCodeToISO6393Configurations.hasOwnProperty(languageCode)
    ? languageCodeToISO6393Configurations[languageCode]
    : languageCodeToISO6393Configurations["en-row"];
};

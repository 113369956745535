import { Subscription } from "rxjs";
import {
  SimpleChange,
  HostListener,
  Component,
  OnDestroy,
  OnChanges,
  Input,
} from "@angular/core";

// Configurations
import { languageToPIQConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import _PaymentIQCashier from "paymentiq-cashier-bootstrapper";
import * as _ from "underscore";
import * as $ from "jquery";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";
import { UtilityService } from "src/app/utility/utility.service";

interface PIQObject {
  sessionId: string;
  userId: string;
  merchantId: string;
  method: string;
  ipAddr: string;
}

@Component({
  selector: "app-hosted-cashier",
  templateUrl: "./hosted-cashier.component.html",
  styleUrls: ["./hosted-cashier.component.scss"],
})
export class HostedCashierComponent implements OnChanges, OnDestroy {
  getTokenAjaxSub: Subscription;
  cashierInstance: any;
  isRedirectNewProviderWindow: boolean = false;
  langCodeSubscription: Subscription;
  langCode: string;
  isLoading: boolean;
  profileBalanceSub: Subscription;
  currencyCode: string;
  currencyCodeSub: Subscription;
  paymentIQAPICallbackInstance;
  gameGroupGameSub: Subscription;
  games: any;
  finalFilteredBonusData = [];
  bonusDetails: any;

  /**
   * CreditCard is the default selected payment method
   * for all regions
   */
  selectedMethodDetails = {
    method_name: "creditcard",
    provider_name: "unknown",
  };
  _CHANNEL_: string = "";
  sideMenuTitleText;
  constructor(
    private cashierService: CashierService,
    private userDetailsService: UserDetailsService,
    private commonService: CommonService,
    public utils: UtilityService,
    private emitterService: EmitterService,
    private translationService: TranslationService,
    private gtmService: GtmService
  ) {
    this._CHANNEL_ = this.utils.getDeviceType();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.getWindowType();
    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (code) => {
        this.currencyCode = code;
      }
    );
  }

  @Input() cashierType;
  @Input() cashierStep;
  piqObject: PIQObject = {
    sessionId: "",
    userId: environment.paymentIqUID,
    merchantId: environment.paymentIqMID,
    method: "",
    ipAddr: "",
  };
  avialableDepositBonusDetails: any;
  windowType: string;
  selectedBonus: any;
  clearSelectedBonus: boolean = false;
  balanceDetails;
  isCashierIframeLoaded: boolean = false;
  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["cashierType"]) {
      this.cashierType = changes["cashierType"].currentValue;
      this.resetValues();
      this.setUpCashier();
      if (this.cashierType === "deposit") {
        this.sideMenuTitleText = this.translationService.instant(
          "cashier.add_money"
        );
      } else if (this.cashierType === "withdrawal") {
        this.sideMenuTitleText = this.translationService.instant(
          "cashier.withdraw"
        );
      }
    }
  }

  resetValues() {
    this.isRedirectNewProviderWindow = false;
    this.avialableDepositBonusDetails = undefined;
    this.isCashierIframeLoaded = false;
    this.isLoading = false;
    this.selectedBonus = undefined;
    if (
      this.commonService.getActiveDepositBonus() &&
      this.cashierType === "withdrawal"
    ) {
      this.commonService.setActiveDepositBonus(undefined);
    }
    if ($("#cashier").contents("iframe").length) {
      $("#cashier").empty();
    }
  }

  setUpCashier() {
    const profileDetails = this.userDetailsService.getUserProfileDetails();
    this.isLoading = true;
    this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
    if (!_.isEmpty(profileDetails) && !_.isEmpty(this.balanceDetails)) {
      this.piqObject.userId = profileDetails.playerID;
      this.getCashierToken();
    } else {
      const getTokenObservable = this.cashierService.getToken({
        token: "cashier",
      });
      const getProfileDataObservable = this.commonService.getProfileBalanceCurrency();
      const getUserbalanceBypockets = this.commonService.getUserBalanceByPockets();
      Promise.all([
        getTokenObservable,
        getProfileDataObservable,
        getUserbalanceBypockets,
      ]).then((results) => {
        const profileData: any = results[1];
        if (
          profileData &&
          profileData.profile &&
          profileData.profile.playerID &&
          results[2]
        ) {
          this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
          this.piqObject.userId = profileData.profile.playerID;
          const tokenData = results[0];
          this.parseTokenData(tokenData);
        }
      });
    }
  }

  getCashierToken() {
    Promise.resolve(this.cashierService.getToken({ token: "cashier" })).then(
      (tokenDetails: any) => {
        if (
          tokenDetails.status === "SUCCESS" &&
          tokenDetails.token &&
          tokenDetails.token !== ""
        ) {
          this.parseTokenData(tokenDetails);
          this.piqObject.sessionId = tokenDetails.token;
        }
      },
      (usedAccountError) => {
        console.log(usedAccountError);
      }
    );
  }

  parseTokenData(tokenDetails) {
    this.checkForActiveBonus();
    if (
      tokenDetails.status === "SUCCESS" &&
      tokenDetails.token &&
      tokenDetails.token !== ""
    ) {
      this.piqObject.sessionId = tokenDetails.token;
      this.piqObject.merchantId = environment.paymentIqMID;
      this.piqObject.ipAddr = tokenDetails.ipAddr;
      this.setupCashier();
    }
  }

  resetCashier() {
    this._CHANNEL_ = this.utils.getDeviceType();
    window["_PaymentIQCashierReset"]();
    this.setupCashier();
  }

  resetCashierWithBonus() {
    window["_PaymentIQCashierReset"]();
    this._CHANNEL_ = "Bonus";
    this.setupCashier();
  }
  /**
   * We check user device type(like Is it  ISO | Android | Linux | windows | Mac)
   * and then pass this information in Channel property to hosted cashier.
   *
   * But if we have selected bonus we mark this property value(_channel) as "Bonus" and
   * overridding device info (important note..)
   */

  getChannelId() {
    return this._CHANNEL_;
  }

  setupCashier() {
    this.cashierInstance = new _PaymentIQCashier(
      "#cashier",
      this.getCashierConfig() as any,
      (api) => {
        this.paymentIQAPICallbackInstance = api;
        api.on({
          cashierInitLoad: () => {
            if (document.getElementById("cashier")) {
              document.getElementById("cashier").style.opacity = "1";
            }
            if (document.getElementById("loader")) {
              document.getElementById("loader").style.opacity = "0";
            }
            this.userCashierJourney("cashierInitLoad");
          },
          success: (data) => {
            this.closeCashier("sucess-callback", data);
          },
          failure: (failureData) => {
            this.userCashierJourney("failure", failureData["data"]);
            this.closeCashier("failure-callback", failureData);
          },
          pending: (data) => {
            this.closeCashier("pending-callback", data);
          },
          unresolved: (data) => {
            this.closeCashier("unresolved-callback", data);
          },
          doneLoading: (data) => {
            this.isLoading = false;
            this.isCashierIframeLoaded = true;
          },
          newProviderWindow: (data) => {
            /**
             * As we have requirment that rewards cards should along with
             * iframe & it's should not load first.
             *
             * so when below flag becames true  deposit rewards cards will be load
             * in cashier page.
             */
            this.isRedirectNewProviderWindow = true;
          },
          paymentMethodSelect: (selectedPaymentData) => {
            this.selectedMethodDetails = {
              method_name: selectedPaymentData["data"]["txType"],
              provider_name: selectedPaymentData["data"]["providerType"],
            };
            this.userCashierJourney(
              "paymentMethodSelect",
              selectedPaymentData["data"]
            );
          },
          onLoadError: (data) => {
            this.userCashierJourney("onLoadError", data);
          },
          navigate: (data) => {
            this.userCashierJourney("navigate", data["data"]);
          },
          transactionInit: (transactionInitData) => {
            this.userCashierJourney(
              "transactionInit",
              transactionInitData["data"]
            );
          },
        });
        api.css(`
                * {
                    font-family: "Inter", Arial, sans-serif !important;
                }
                
                :root {
                
                    --loader-color: #FFE39A  !important;
                    --spinnerLoaderColor: var(--loader-color)!important;
                    --main-container-top-down: 16px;
                    --main-container-side: 0;
                    --margin-size: 0;
                    --border-color: transparent !important;
                    --inputbackground-color: #222536 !important;
                    --card-bg: var(--inputbackground-color) !important;
                    --cashierbackground-color: transparent !important;
                    --background-color: var(--cashierbackground-color)!important;
                    --input-fontSize: 14px !important;
                    --input-height: 48px !important;
                    --input-color: #EBEDF5 !important;
                    --input-borderRadius: 4px !important;
                    --labels-fontSize: 12px !important;
                    --labels-color: #CECDDB !important;
                    --headings-color: #FFFFFF !important;
                    --buttons-color: #FFE39A !important;
                    --button-hover-color: rgba(#FFE39A, 0.9) !important;
                    --error-color: #ff2e56 !important;
                    --success-color: #4BB543 !important;
                    --border-radius: 4px !important;
                    --margin-size: 16px !important;
                    --submitBtnTextColor: #01062B !important;
                    --submitBtnDisabledBorderColor: #CECDDB;
                    --submitBtnDisabledBgColor: transparent;
                    --config-border-color: transparent !important;
                    --dropdownBorderColor: #333647;
                    --dropdownSelectedBgColor: transparent;
                    --dropdownSelectedTextColor: #FFE39A;
                    --dropdownTextColor: #FFFFFF;
                    --dropdownSelectedActiveTextColor:#FFFFFF;
                    --hosted-fields-border-color: transparent;
                    --hosted-fields-border-focus-color: transparent;
                    --hosted-fields-border-error-color: #FF006B!important;
                    --containerWidth: 100%;
                    --headings-color:  #FFFFFF !important;
                }
                 #cashier {
                    background: var(--cashierbackground-color);
                }
                
                #app-container {
                    height: 100%;
                }
                
                .spinner .spinner-left:after,
                .spinner .spinner-right:after {
                    border-color: var(--spinnerLoaderColor)!important;
                }
                
                .single-page-flow-container {
                    margin: 0 !important;
                    padding: 0 !important;
                }
                
                .payment-method .set-amount input:active,
                .payment-method .set-amount input:focus,
                .input:focus,
                .control .input:focus {
                    border-color: transparent !important;
                }

                .payment-method .hosted-input-container .input-container input.error,
                .hosted-input-container .input-container input.error{
                    opacity: 1px!important;
                }

                .floating-input.input:focus {
                    border-color: transparent !important;
                }
                
                #cashier input:not([type=checkbox]) {
                    border-color: transparent !important;
                }
                
                #cashier .submit-button {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    border-radius: 80px;
                    width: 100%;
                    height: 48px;
                    padding: 0 8px;
                    color: var(--submitBtnTextColor) !important;
                    box-shadow: none;
                }
                
                #cashier .submit-button:disabled {
                    border: 1px solid var(--submitBtnDisabledBorderColor);
                    color: var(--submitBtnDisabledBorderColor) !important;
                    background: var(--submitBtnDisabledBgColor);
                    filter: opacity(100%) !important;
                    cursor: not-allowed;
                }
                
                .dropdown-content {
                    box-shadow: none !important;
                    background:var(--inputbackground-color)!important;
                }
                
                .single-page-flow-container .dropdown-container {
                    padding: 4px 16px !important;
                }
                
                .dropdown-container {
                    border-radius: var(--input-borderRadius) !important;
                }
                
                .dropdown-content .dropdown-item {
                    padding: 11px 16px 9px;
                    border-radius: 0;
                    position: relative;
                    height: 44px;
                    color: var(--dropdownTextColor) !important;
                    font-size: 14px !important;
                    font-weight: bold;
                
                }
                
                .dropdown-content .dropdown-item::after {
                    content: '';
                    border-bottom: 1px solid var(--dropdownBorderColor);
                    position: absolute;
                    bottom: 0;
                    width: calc(100% - 32px);
                    margin: auto;
                    left: 0;
                    right: 0;
                }
                
                .dropdown-content .dropdown-item:last-child::after {
                    display: none;
                }
                
                .dropdown-content[data-v-6bdb4c07] {
                    padding: 0;
                }
                
                .dropdown-content .dropdown-item.selected,
                .dropdown-content .dropdown-item:hover {
                    background: var(--dropdownSelectedBgColor) !important;
                    color: var(--dropdownSelectedTextColor) !important;
                }
                
                .dropdown-content .dropdown-item .headings-font-size {
                    margin-top: 2px;
                }
                
                .dropdown-content .dropdown-item .headings-font-size,
                .dropdown-toggler span {
                    max-width: 70%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                
                }
                
                .dropdown-toggler ion-icon {
                    font-size: 17px !important;
                    color: var(--dropdownSelectedActiveTextColor);
                }
                
                .dropdown-toggler span {
                    color: var(--dropdownSelectedActiveTextColor);
                    font-size: 14px !important;
                    font-weight: bold;
                }
                
                .dropdown:not(.account-dropdown) .logo-container .logo-icon {
                    min-height: 32px !important;
                    max-height: 32px !important;
                    bottom: 6px;
                }
                
                .input-border .dropdown-toggler {
                    padding: 4px 16px !important;
                }
                
                .input-border input {
                    padding-left: 16px !important;
                    padding-right: 16px !important;
                }
                
                .input-wrapper .input-label-float.active {
                    margin-top: 5px !important;
                    margin-left: 0px !important;
                }
                
                .input-border .input-label-float {
                    left: 16px !important;
                }
                
                input:-ms-input-placeholder {
                    color: var(--labels-color) !important;
                }
                
                input::-ms-input-placeholder {
                    color: var(--labels-color) !important;
                }
                
                input::placeholder {
                    color: var(--labels-color) !important;
                    opacity: 1;
                }
                
                .label-input-floating,
                .input-wrapper .input-label-float,
                #label-cccard.label-input-floating.active,
                .hosted-input-container .input-container .label-input-floating.active {
                    color: var(--labels-color) !important;
                    opacity: 1;
                }
                
                .input-wrapper .input-label-float {
                    left: 16px !important;
                
                }
                
                #hosted-field-container-frmCCCVC,
                #hosted-field-container-frmCCExp {
                    margin-top: 12px !important;
                }
                
                .hosted-input-container .input-container input {
                    font-weight: 700;
                }
                
                .notice,
                .notice-container {
                    width: 100% !important;
                    margin: 0 auto !important;
                
                }
                
                [class*="-custom-template"] {
                    background: var(--card-bg) !important;
                    padding: 16px !important;
                    border-radius: var(--input-borderRadius) !important;
                }
                
                [class*="-custom-template"] * {
                    color: var(--labels-color) !important;
                    font-size: 14px;
                    font-weight: bold;
                }
                
                .selected-account-container .selected-dropdown-provider-label{
                    min-width:150px;
                    text-align: left;
                }
                .link {
                    color: #0059ff !important;
                }
                .pending-status-polling-tries{
                    color: var(--headings-color) !important;
                }
                .disable-app-overlay-container .disable-app-overlay{
                    background: #090B19 !important;
                    pointer-events: none!important;
                    cursor:not-allowed;
                }
            `);
      }
    );
  }

  getCashierConfig() {
    let cashierConfig = {
      merchantId: this.piqObject.merchantId,
      userId: this.piqObject.userId,
      sessionId: this.piqObject.sessionId,
      environment: environment.production ? "production" : "test", //production, // if not set, defaults to production
      method: this.cashierType, // if not set, defaults to deposit (deposit or withdrawal),
      locale: languageToPIQConfigurations[this.langCode], // Available values: sv_SE, en_US, en_GB, no_NO, fi_FI, pt_BR
      mode: "gambling", //Set a mode for the cashier (gambling or ecommerce),
      gaTag: "UA-173996710-1", // Pass in your own Google analytic
      showAmountLimits: true,
      showReceipt: true,
      receiptAmountDisplayStyle: "symbol",
      channelId: this.getChannelId(),
      containerHeight: "auto",
      containerMinHeight: "400px",
      showAccounts: "inline",
      amountFirst: false, // default is false..we set it to true to show amount feild
      fetchConfig: true,
      showFooter: false,
      displayLogoOrName: "name",
      zebraffeLoader: false,
      font: "google, Inter",
      attributes: {
        labelId: "highroller",
        productId: "CASINO",
        ipAddr: this.piqObject.ipAddr,
      },
    };
    if (this.selectedBonus && this.selectedBonus["bonusCode"]) {
      cashierConfig["attributes"]["bnsCode"] = this.selectedBonus["bonusCode"];
    }
    return cashierConfig;
  }

  closeCashier(callingFrom?, responseData?) {
    if (this.cashierType === "deposit" && callingFrom === "sucess-callback") {
      this.updateDespositSuccessGTMEvent(responseData);
      this.emitterService.broadCastDepositSuccesful(responseData);
    }
    setTimeout(() => {
      this.closeComponent();
    }, 5000);
  }

  updateDespositSuccessGTMEvent(responseData) {
    if (
      this.userDetailsService.getUserProfileDetails() &&
      responseData["data"]["payload"]
    ) {
      let eventPayload = {
        event: "user_interaction",
        event_type: "deposit",
        currency: this.currencyCode,
        txAmount: responseData["data"]["payload"]["txAmount"],
        transactioRefId: responseData["data"]["payload"]["txRefId"],
        userId: this.userDetailsService.getUserProfileDetails().playerID,
        event_context:
          this.userDetailsService.getUserProfileDetails().userStatus === "play"
            ? "first"
            : "subsequent",
        country: this.userDetailsService.getUserProfileDetails().country,
        payment_method: this.selectedMethodDetails["method_name"],
        payment_status: "SUCCESS",
      };
      if (this.selectedMethodDetails["provider_name"]) {
        eventPayload["provider_name"] = this.selectedMethodDetails[
          "provider_name"
        ];
      }
      if (this.selectedBonus && this.selectedBonus["bonusCode"]) {
        eventPayload["bonusCode"] = this.selectedBonus["bonusCode"];
      }
      this.gtmService.gtmDataLayerEvent(eventPayload);
    }
  }

  /**
   * Below code complete deal with passing of bonus code
   * in make transaction
   * Below we check is there any active bonus already been selected &
   * user navigated to cashier like we have reward area where user can click on
   * reward card & land on cashier page
   * so we store bonus selected in rewards component into service
   * & fetch back here to apply & passed it on pass in payment flow
   */

  checkForActiveBonus() {
    let activeDepositBonus = this.commonService.getActiveDepositBonus();
    if (activeDepositBonus && activeDepositBonus["bonusCode"]) {
      this.selectedBonus = activeDepositBonus;
      this._CHANNEL_ = "Bonus";
    }
  }

  selectedBonusHandler(data) {
    this.selectedBonus = data;
    if (data && data.bonusCode) {
      this.clearSelectedBonus = false;
      this.resetCashierWithBonus();
    } else {
      this.clearSelectedBonus = true;
      this.resetCashier();
    }
  }

  getWindowType() {
    const ww = document.body.clientWidth;
    if (ww <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  closeComponent(navigateTo?) {
    this.utils.closeAccountComponent(navigateTo);
  }

  userCashierJourney(callingFrom, cashierPayload = {}) {
    if (this.selectedBonus && this.selectedBonus["bonusCode"]) {
      cashierPayload["bonusCode"] = this.selectedBonus["bonusCode"];
    }
    cashierPayload[
      "userId"
    ] = this.userDetailsService.getUserProfileDetails().playerID;
    cashierPayload = { ...cashierPayload, ...this.selectedMethodDetails };
    if (this.cashierType === "deposit") {
      this.gtmService.userCashierJourneyEvents(callingFrom, cashierPayload);
    }
  }

  ngOnDestroy() {
    if (
      this.userDetailsService.getUserProfileDetails() &&
      this.userDetailsService.getUserProfileDetails().userStatus === "play"
    ) {
      Promise.resolve(this.commonService.getProfileBalanceCurrency()).then();
    }
    /**
     * We have to clear saved active bonus if their is any active bonus saved in our local,
     * basically when user navigaye from all -rewards deposit card we store it their
     * & activate that bonus by default once he lands on cashier page
     */
    if (this.commonService.getActiveDepositBonus()) {
      this.commonService.setActiveDepositBonus(undefined);
    }
    if (this.profileBalanceSub) {
      this.profileBalanceSub.unsubscribe();
    }
    this.currencyCodeSub.unsubscribe();
    this.langCodeSubscription.unsubscribe();
  }
}

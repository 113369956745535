import { CommonModule, DecimalPipe, TitleCasePipe } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";

// Components
import { ReleaseRestrictionRewardComponent } from "src/app/modules/rewards/components/release-restrict-reward/release-restrict-reward.component";
import { CashbackPromoRewardComponent } from "src/app/modules/rewards/components/cashback-promo-reward/cashback-promo-reward.component";
import { DepositRewardCardComponent } from "src/app/modules/rewards/components/deposit-reward-card/deposit-reward-card.component";
import { PayNPlayCashierComponent } from "src/app/modules/account/components/pay-n-play-cashier/pay-n-play-cashier.component";
import { QuickpickAmountComponent } from "src/app/modules/account/components/quickpick-amount/quickpick-amount.component";
import { SubscribeOffersComponent } from "src/app/modules/shared/components/subscribe-offers/subscribe-offers.component";
import { SidemenuModalComponent } from "src/app/modules/navigation/components/sidemenu-modal/sidemenu-modal.component";
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";
import { CountdownTimerComponent } from "src/app/modules/shared/components/countdown-timer/countdown-timer.component";
import { CashoutRewardComponent } from "src/app/modules/rewards/components/cashout-reward/cashout-reward.component";
import { PageNotFoundComponent } from "src/app/modules/shared/components/page-not-found/page-not-found.component";
import { PaymentBonusComponent } from "src/app/modules/account/components/payment-bonus/payment-bonus.component";
import { ButtonLoaderComponent } from "src/app/modules/shared/components/button-loader/button-loader.component";
import { CustomBannerComponent } from "src/app/modules/banner/components/custom-banner/custom-banner.component";
import { LoaderComponent } from "src/app/modules/shared/components/loader/loader.component";
import { LeaderBoardComponent } from 'src/app/modules/shared/components/leader-board/leader-board.component';
import { LeaderBoardTableComponent } from 'src/app/modules/shared/components/leader-board/leader-board-table/leader-board-table.component';

// Directives
import { MarketBasedComponentDisplayDirective } from "src/app/modules/shared/directives/market-based-component-display.directive";
import { PlayNPlayCashierDirective } from "src/app/modules/shared/directives/pay-n-play-cashier.directive";
import { SwiperResizeDirective } from "src/app/modules/shared/directives/swiper-resize.directive";
import { NumberDirective } from "src/app/modules/shared/directives/number.directive";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperModule } from "ngx-swiper-wrapper";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";
import { AccordionModule } from "ngx-accordion";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";
import { SafeHtmlPipe } from "src/app/modules/shared/pipes/safe-html.pipe";
import { LeaderBoardUserNamePipe } from './pipes/leader-board-user-name.pipe';
import { IsEqualPipe } from './pipes/is-equal.pipe';

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { RouterModule } from "@angular/router";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    environment.langpath,
    `.json?cb=${new Date().getTime()}`
  );
}

@NgModule({
  declarations: [
    MarketBasedComponentDisplayDirective,
    ReleaseRestrictionRewardComponent,
    CashbackPromoRewardComponent,
    DepositRewardCardComponent,
    PlayNPlayCashierDirective,
    PayNPlayCashierComponent,
    SubscribeOffersComponent,
    QuickpickAmountComponent,
    FormValidationComponent,
    CountdownTimerComponent,
    CashoutRewardComponent,
    SidemenuModalComponent,
    CustomBannerComponent,
    PageNotFoundComponent,
    SwiperResizeDirective,
    ButtonLoaderComponent,
    PaymentBonusComponent,
    CurrencyFormatPipe,
    LoaderComponent,
    GamesFilterPipe,
    NumberDirective,
    SafeHtmlPipe,
    LeaderBoardComponent,
    LeaderBoardTableComponent,
    LeaderBoardUserNamePipe,
    IsEqualPipe,
  ],
  entryComponents: [
    PayNPlayCashierComponent,
    SubscribeOffersComponent,
    QuickpickAmountComponent,
    LeaderBoardComponent
  ],
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    AccordionModule,
    CommonModule,
    RouterModule,
    SwiperModule,
    FormsModule,
    MbscModule,
  ],
  providers: [
    CustomBannerService,
    CurrencyFormatPipe,
    GamesFilterPipe,
    TitleCasePipe,
    DecimalPipe
  ],
  exports: [
    MarketBasedComponentDisplayDirective,
    ReleaseRestrictionRewardComponent,
    CashbackPromoRewardComponent,
    DepositRewardCardComponent,
    PlayNPlayCashierDirective,
    PayNPlayCashierComponent,
    SubscribeOffersComponent,
    QuickpickAmountComponent,
    FormValidationComponent,
    CountdownTimerComponent,
    CashoutRewardComponent,
    SidemenuModalComponent,
    CustomBannerComponent,
    PageNotFoundComponent,
    SwiperResizeDirective,
    ButtonLoaderComponent,
    PaymentBonusComponent,
    CurrencyFormatPipe,
    HttpClientModule,
    LoaderComponent,
    TranslateModule,
    GamesFilterPipe,
    AccordionModule,
    NumberDirective,
    CommonModule,
    SwiperModule,
    SafeHtmlPipe,
    DecimalPipe,
    FormsModule,
  ],
})
export class SharedModule {}

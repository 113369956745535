import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Output,
  Input
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Services
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-unclaimed-free-spin-reward-card",
  templateUrl: "./unclaimed-free-spin-reward-card.component.html",
  styleUrls: ["./unclaimed-free-spin-reward-card.component.scss"],
})
export class UnclaimedFreeSpinRewardCardComponent
  implements OnChanges, OnDestroy {
  @Input() bonusDetails;
  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  /**This call back emitter required on pnp flow only */
  @Output() callOnLevelupClaimed: EventEmitter<any> = new EventEmitter();
  bonusType: string;
  apiUrlPath: string = environment.apiUrl;
  isLevelUpClaimFailed: boolean = false;
  isRequestInProcess: boolean = false;
  levelupBonusSub: Subscription;
  isButtonLoader: boolean = false;
  constructor(
    private utility: UtilityService,
    private rewardService: RewardsService,
    private liveChatService: LiveChatService
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
      this.bonusType = this.bonusDetails["bonusTypeFD"];
    }
  }

  applyLevelupBonus(levelId: number) {
    if (this.utility.isPnpFlow()) {
      this.claimLevelupBonus(levelId);
    } else {
      this.rewardService.setlevelUpDetails({
        newLevelId: levelId,
        dataFrom: "rewards",
      });
      this.utility.openAccountComponent("reward-congratulations");
    }
  }

  claimLevelupBonus(levelId: number) {
    this.isRequestInProcess = true;

    this.isLevelUpClaimFailed = false;
    this.levelupBonusSub = this.rewardService
      .claimLevelupBonus({ levelId: levelId })
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          this.callOnLevelupClaimed.emit();
        } else {
          this.isLevelUpClaimFailed = true;
        }
        this.isRequestInProcess = false;
      });
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }

  initChat() {
    this.liveChatService.onInitializeChat();
    this.utility.closeAccountComponent();
  }

  ngOnDestroy() {
    this.levelupBonusSub.unsubscribe();
  }
}

export enum LanguageCodes {
  // ENGLISH
  en = "en",
  // AUSTRIA
  at = "at",
  // GERMAN
  de = "de",
  // CANADA
  cd = "cd",
  // NEW_ZEALAND
  nz = "nz",
  // FINNISH
  fi = "fi",
  // NORWEGIAN
  no = "no",
  // IRELAND
  ie = "ie",
  // NORWAY
  nb = "nb",
  // HUNGARIAN
  hu = "hu",
  // ESTONIA
  et = "et",
  // en-row
  "en-row" = "en-row",
  // de-eu
  "de-eu" = "de-eu",
  // en-ca
  "en-ca" = "en-ca",
  // en-nz
  "en-nz" = "en-nz",
  // fi-fi
  "fi-fi" = "fi-fi",
  // nb-no
  "nb-no" = "nb-no",
  // en-ie
  "en-ie" = "en-ie",
  // hu-hu
  "hu-hu" = "hu-hu",
  // et-ee
  "et-ee" = "et-ee"
}

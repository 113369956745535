import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
  OnInit,
} from "@angular/core";

// Configurations
import {
  excludeLanguageOptionsConfigurations,
  localToCmsLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Services
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild("footerConatiner", { static: false }) footerConatiner: ElementRef;

  // Strings
  staticContent: string = "";
  countryCode: string = "";
  languageCode: string = "";

  // Booleans
  isLangOpen: boolean = false;
  isLoggedIn: boolean = false;

  // Arrays
  availableLanguages: string[] = [];
  acceptedLanguages: string[] = [];
  excludedLangauges: string[] = [];

  // Others
  localeDisplayName: {
    [key: string]: string;
  } = {
    "en-row": "English",
    "de-eu": "Austria",
    "en-ca": "Canada",
    "fi-fi": "Finland",
    "nb-no": "Norwegian",
    "en-nz": "New Zealand",
    // "en-eu": "NetherLand",
    "ja-jp": "Japanese",
    "en-ie": "Ireland",
    ...(environment.features.hungarian ? {"hu-hu": "Hungarian"} : {}),
    ...(environment.features.markets.estonia ? {"et-ee": "Estonia"} : {})
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private staticPageService: StaticPageService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private utility: UtilityService,
    private router: Router
  ) {
    
  }

  @HostListener("document:click", ["$event"])
  @HostListener("document:touchend", ["$event"])
  onClick(event): void {
    if (event.target.classList.contains("overlay-backdrop--footer")) {
      this.isLangOpen = false;
    }
  }

  @HostListener("window:popstate", ["$event"])
  onBrowserBackBtnClose(event: Event): void {
    let languageFromUrl: string = window.location.pathname.split("/")[1];
    if (event.type === "popstate" && languageFromUrl !== this.languageCode) {
      this.changeLanguageHandler(languageFromUrl);
    }
  }

  ngOnInit() {
    this.excludedLangauges = excludeLanguageOptionsConfigurations;
    this.filterPreferredLanguages();
    this.isLoggedIn = this.utility.isUserLoggedIn();
    
    this.languageCode = this.utility.getLangCode();

    this.subscriptions = [
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
      this.translationService.langCode$.subscribe((languageCode: string) => {
        if (this.languageCode !== languageCode) {
          this.loadFooterContent(true);
        }
        this.languageCode = languageCode;
      })
    ];

    this.availableLanguages = this.translationService.getLangs();

    this.loadFooterContent();
  }

  ngAfterViewInit(): void {

  }

  /**
   * Here we remove Netherland & german option for locale drowdrop
   * as per client requirment,but user can use it from url directly
   * just we don't display in locale downdrop.
   */

  filterPreferredLanguages(): void {
    this.acceptedLanguages = supportedLanguagesList();
    this.excludedLangauges.forEach((languageCode: string) => {
      this.acceptedLanguages.splice(
        this.acceptedLanguages.indexOf(languageCode),
        1
      );
    });
  }

  loadFooterContent(isForce?: boolean): void {
    let url: string = "footer";

    Promise.resolve(this.staticPageService.getStaticPagep(url, isForce)).then(
      (footerData) => {
        if (
          footerData &&
          Object.keys(footerData).length > 0 &&
          !footerData["errorCode"] &&
          footerData["content"]
        ) {
          this.staticContent = this.utility.replaceAll(footerData.content, {
            "{{site_url}}": environment.siteUrl,
            "{{media_url}}": environment.mediaUrl,
          });
        }
      }
    );
  }

  dynamicFooterContent(event): void {
    const routerLink = event.target.getAttribute("routerLink");
    if (routerLink && routerLink.length > 0) {
      this.utility.cmsRoutingHandler(routerLink);
    }
  }

  changeLanguageHandler(languageCode: string): void {
    this.languageCode = languageCode;
    this.translationService.use(
      localToCmsLanguageConfigurations[this.languageCode]
    );

    localStorage.setItem("langCode", languageCode);
    this.reloadPage();
  }

  reloadPage(): void {
    const params = this.utility.getQueryParams();
    this.navigateToLandingPage(params);

    setTimeout((_) => {
      window.location.reload();
    });
  }

  navigateToLandingPage(params = {}): void {
    if (this.utility.isUserLoggedIn() || this.utility.isPnpFlow(this.languageCode)) {
      this.router.navigate([`${this.languageCode}/casino`], {
        queryParams: params,
      });
    } else {
      this.router.navigate([this.languageCode], { queryParams: params });
    }
  }

  navigateAfterSetLang(path: string): void {
    if (window.location.search.length > 0) {
      const params = this.utility.getQueryParams();
      this.router.navigate([path], { queryParams: params });
    } else {
      this.router.navigate([path]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}

import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Libraries
import * as _ from "underscore";

// Models
import { ProfileCountryDetails } from "src/app/modules/user/models/profile-country-details.model";
import { ProfileCountries } from "src/app/modules/user/models/profile-countries.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

@Component({
  selector: "app-update-profile",
  templateUrl: "./update-profile.component.html",
  styleUrls: ["./update-profile.component.scss"],
})
export class UpdateProfileComponent implements OnInit, OnDestroy {

  // Form Group
  profileUpdateForm: FormGroup;

  // Arrays
  isoBasedCountryList: ProfileCountries[] = [];

  // Others
  profileDetails: UserData;

  // Subscriptions
  countryDetailsSubscription: Subscription;
  userDataSubscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private customValidators: CustomValidators,
    public liveChatService: LiveChatService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private utility: UtilityService,
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit() {
    this.profileUpdateForm = this.formBuilder.group({
      firstName: ["", [this.customValidators.validName(2, 30)]],
      lastName: ["", [this.customValidators.validName(2, 30)]],
      address1: ["", [this.customValidators.maxMinCharacters(2, 80)]],
      city: ["", [this.customValidators.maxMinCharacters(2, 50)]],
      zip: ["", [this.customValidators.validAlphaNumeric(2, 9)]],
      country: ["", [this.customValidators.required]],
      phone: ["", []],
      email: ["", []],
    });

    this.countryDetailsSubscription = this.commonService
      .getProfileCountryDetails()
      .subscribe((data: ProfileCountryDetails) => {
        if (data && data.countrylist) {
          this.isoBasedCountryList = data.countrylist;
        }
      });

    if (!this.userDetailsService.getUserProfileDetails()) {
      this.profileDetails = this.userDetailsService.getUserProfileDetails();

      this.setFormData(this.profileDetails);
    } else {
      this.userDataSubscription = this.commonService.getUserData().subscribe((profileDetails: UserData) => {
        this.profileDetails = profileDetails;

        this.setFormData(this.profileDetails);
      });
    }
  }

  setFormData(profileDetails: UserData): void {
    let formGroup: FormGroup = this.profileUpdateForm;

    if (profileDetails) {
      Object.keys(formGroup.controls).forEach((formControlName: string) => {
        const control: AbstractControl = formGroup.get(formControlName);
        
        let profileValue: string = "";

        if (formControlName === "dob") {
          profileValue = profileDetails.birthDate;
        } else if (formControlName === "phone") {
          profileValue = `+${profileDetails.cellPhoneAreaCode}${profileDetails.cellPhone}`;
        } else if (formControlName === "country" && this.isoBasedCountryList) {
          let filteredData: ProfileCountries = _.findWhere(this.isoBasedCountryList, {
            iso: profileDetails.country,
          });

          profileValue = filteredData ? filteredData.name : "";
        } else {
          profileValue = profileDetails[formControlName];
        }

        if (profileValue) { 
          control.setValue(profileValue);
        }
      });
    }
  }

  initLiveChat(): void {
    this.liveChatService.onInitializeChat();

    this.utility.closeAccountComponent();
  }

  ngOnDestroy(): void {
    if (this.countryDetailsSubscription) this.countryDetailsSubscription.unsubscribe();
    
    if (this.userDataSubscription) this.userDataSubscription.unsubscribe();
  }
}

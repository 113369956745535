import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Libraries
import * as _ from "underscore";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-reward-congrats",
  templateUrl: "./reward-congrats.component.html",
  styleUrls: ["./reward-congrats.component.scss"],
})
export class RewardCongratsComponent implements OnInit, OnDestroy {
  currentBonusId;
  freeSpinGameId;
  currentLevelId;
  freeSpinGameData;
  currentBonusData;
  gameGroupGamesData;
  isLevelUpClaimed: any = false;
  levelUpClaimErrorMessage: string;
  isbuttonDisable: any = false;

  bonusDetails: any;

  langCode: string;

  currentLevelUpData;
  isRewardClimed: boolean = false;
  // mediaUrlPath: string = environment.mediaUrlPath;
  langCodeSub: Subscription;
  levelupBonusSub: Subscription;
  gameGroupGamesDataSub: Subscription;
  coin: boolean = false;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  // imgixParams: string;

  constructor(
    private router: Router,
    private utility: UtilityService,
    private rewardService: RewardsService,
    private gamePlayService: GamePlayService,
    private gameGroupsService: GameGroupsService,
    private translationService: TranslationService,
    public liveChatService: LiveChatService,
    private userDetailsService: UserDetailsService,
    private commonService: CommonService
  ) {
    let levelUpDetails = this.rewardService.getlevelUpDetails();
    if (levelUpDetails && Object.keys(levelUpDetails).length > 0) {
      this.currentLevelUpData = levelUpDetails;
      this.currentLevelUpData["freeSpinCount"] = levelUpDetails["spinCount"]
        ? levelUpDetails["spinCount"]
        : 0;
      this.currentLevelUpData["freeSpinValue"] = levelUpDetails["spinValue"]
        ? levelUpDetails["spinValue"]
        : 0;
    }
    this.langCode = this.utility.getLangCode();
    this.langCodeSub = this.translationService.langCode$.subscribe(
      (langcode) => {
        this.langCode = langcode;
      }
    );

    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );

    // this.imgixParams = this.utility.getImgixParams();
  }

  ngOnInit() {}

  claimLevelupBonus(levelId: number) {
    this.isbuttonDisable = true;
    this.isLevelUpClaimed = false;
    this.startSpinner();
    this.levelupBonusSub = this.rewardService
      .claimLevelupBonus({ levelId: levelId })
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          if (this.currentLevelUpData["dataFrom"] === "socket") {
            this.appendGameData();
          } else if (this.currentLevelUpData["dataFrom"] === "rewards") {
            this.getActiveBonusData(data["ecrBonusId"]);
          }
          this.rewardService.broadLevelUpDetails(undefined);
        } else {
          this.showErrorScreen(data);
        }
      });
  }

  showErrorScreen(data?) {
    this.isLevelUpClaimed = "error";
    this.isbuttonDisable = false;
    if (data && data["errors"]) {
      this.levelUpClaimErrorMessage = data["errors"]["errorDescription"];
    } else {
      this.levelUpClaimErrorMessage =
        "Something went wrong,please customer support";
    }
  }

  appendGameData() {
    this.gameGroupGamesDataSub = this.gameGroupsService
      .getGameGroupGames()
      .subscribe((games) => {
        if (games && games.length > 0) {
          let filteredGameData;
          if (this.currentLevelUpData["dataFrom"] === "socket") {
            filteredGameData = _.find(games, {
              pristineGameId: this.currentLevelUpData["gameId"],
            });
          } else {
            filteredGameData = _.find(games, {
              gameCode: this.currentLevelUpData["gameId"],
            });
          }
          if (filteredGameData && Object.keys(filteredGameData).length > 0) {
            this.currentLevelUpData["gameData"] = filteredGameData;
            setTimeout(() => {
              this.coin = false;
              this.isLevelUpClaimed = true;
              this.isbuttonDisable = false;
            });
          } else {
            this.showErrorScreen();
          }
        }
      });
  }

  startSpinner() {
    this.coin = true;
  }

  launchGame(gameName) {
    gameName = this.utility.convertGameNameToUrl(gameName);
    this.gamePlayService.setGameCalledfrom("realgame");
    let currentPath = this.utility.getDecodedCurrentPath();
    if (
      currentPath.includes(
        this.translationService.instant("url.game") + "/" + gameName
      )
    ) {
      this.gamePlayService.broadCastRelanuchGame(true);
    } else {
      this.router.navigate([
        this.langCode +
          "/" +
          this.translationService.instant("url.game") +
          "/" +
          gameName,
      ]);
    }
    this.utility.closeAccountComponent();
  }

  openRewardTCPopUp(event) {
    if (event) {
      event.stopPropagation();
    }
    this.utility.toggleRewardTCPopUp(true);
  }

  activeBonusSub: Subscription;
  getActiveBonusData(claimedBonusId) {
    if (claimedBonusId) {
      this.activeBonusSub = this.rewardService
        .getActiveBonusDetails()
        .subscribe((activeBonusResp) => {
          if (activeBonusResp && activeBonusResp.length > 0) {
            const filteredData = _.filter(activeBonusResp, (data) => {
              return data["ecrBonusId"] == claimedBonusId;
            });
            if (filteredData && Object.keys(filteredData).length > 0) {
              let bonusDetails = filteredData[0];
              if (
                bonusDetails &&
                bonusDetails["freeSpinGameIds"] &&
                bonusDetails["freeSpinGameIds"].length > 0
              ) {
                let bonusData = {
                  ecrId: bonusDetails["ecrExternalId"],
                  newLevelId: 6,
                  bonusId: bonusDetails["bonusID"],
                  ecrBonusId: 0,
                  gameId: bonusDetails["freeSpinGameIds"][0],
                  freeSpinCount: bonusDetails["freeSpinCount"],
                  freeSpinValue: bonusDetails["freeSpinValue"],
                  messageName: "LEVELUP_LEVEL_UPGRADE",
                  messageType: "LEVELUP",
                  freespinExpiryDate: bonusDetails["freespinExpiryDate"],
                  offeredDate: bonusDetails["offeredDate"],
                };
                this.currentLevelUpData = {
                  ...this.currentLevelUpData,
                  ...bonusData,
                };
                this.appendGameData();
              } else {
                this.showErrorScreen();
              }
            }
          } else {
            this.showErrorScreen();
          }
        });
    }
  }

  closeComponent(navigateTo?) {
    this.utility.closeAccountComponent(navigateTo);
  }

  initLiveChat() {
    this.liveChatService.onInitializeChat();
    this.closeComponent();
  }

  ngOnDestroy() {
    this.langCodeSub.unsubscribe();
    this.currencySymbolSubscription.unsubscribe();
    if (this.activeBonusSub) {
      this.activeBonusSub.unsubscribe();
    }
    if (this.levelupBonusSub) {
      this.levelupBonusSub.unsubscribe();
    }
    if (this.gameGroupGamesDataSub) {
      this.gameGroupGamesDataSub.unsubscribe();
    }
  }
}

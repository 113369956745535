import { animate, style, transition, trigger } from "@angular/animations";
import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-account-menu",
  templateUrl: "./account-menu.component.html",
  styleUrls: ["./account-menu.component.scss"],
  animations: [
    trigger("modalInOut", [
      transition(":enter", [
        style({ opacity: ".7" }),
        animate("100ms ease-in", style({ opacity: "1" })),
      ]),
      transition(":leave", [
        animate("100ms ease-in", style({ opacity: ".7" })),
      ]),
    ]),
  ],
})
export class AccountMenuComponent implements OnInit {
  windowType: string;
  userCountry: string;

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  langCode: string;
  username: string;
  userWithdrawableBalance: number = 0;
  userBonusBalance: number = 0;
  currencySymbol: string;
  activeView: string = "";
  cashierStep;
  langCodeSubscription: Subscription;
  userBalanceSubscription: Subscription;
  userProfileSubscription: Subscription;
  currencySymbolSubscription: Subscription;
  isLoggedIn: boolean = false;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  activeAccountViewSub: Subscription;
  transactionStatus: Subscription;
  currentLevelSub: Subscription;
  currentLevelID: number = 0;
  activeLeftMenuOption: any;
  activeMenuSub: Subscription;
  mediaUrlPath: string = environment.mediaUrlPath;
  iskycEnabled: boolean = true;
  userKycLevelSubs: Subscription;
  countryCode;
  countryCodeSub: Subscription;
  UserFlowTypes: typeof UserFlowTypes;
  levelUpNotificationCount: number;
  clearLevelupNotSub: Subscription;
  updatelevelCountSub: Subscription;
  imgixParams: string;
  isPnpFlow: boolean = false;

  constructor(
    private router: Router,
    public utility: UtilityService,
    private rewardsService: RewardsService,
    private commonService: CommonService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    public liveChatService: LiveChatService,
    public gamePlayService: GamePlayService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.UserFlowTypes = UserFlowTypes;
    this.isPnpFlow = this.utility.isPnpFlow();
    if (this.commonService.getCountryCode()) {
      this.countryCode = this.commonService.getCountryCode();
    }
    this.countryCodeSub = this.commonService.updateCountryCode$.subscribe(
      (countryCode) => {
        this.countryCode = countryCode;
      }
    );
    this.activeAccountViewSub = this.commonService.activeAccountView$.subscribe(
      (tabname) => {
        if (tabname && tabname.includes("transaction")) {
          this.cashierStep = "transaction";
          this.activeView = tabname.split("-")[0];
        } else {
          this.cashierStep = "";
          this.activeView = tabname;
        }
        if (this.activeView === "menuOptions" && this.isLoggedIn) {
          this.getUserKycLevelDetails();
          this.getCurrentUserLevel();
        }
        if (this.activeView) {
          this.utility.addClassToAppBody("overflow-hidden");
        } else {
          this.utility.removeClassFromAppBody("overflow-hidden");
        }
        this.utility.updateActiveLeftMenu(this.activeView);
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
        this.isPnpFlow = this.utility.isPnpFlow();
      }
    );
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );
    this.userBalanceSubscription = this.userDetailsService.userBalanceUpdated$.subscribe(
      (balanceUpdateMessage) => {
        this.userBonusBalance = this.userDetailsService.getUserTotalBonus();
        this.userWithdrawableBalance = this.userDetailsService.getWithdrawableBalance();
      }
    );
    this.userProfileSubscription = this.userDetailsService.userProfileUpdated$.subscribe(
      (data) => {
        const userProfileData = this.userDetailsService.getUserProfileDetails();
        if (userProfileData) {
          this.username = userProfileData["firstName"];
        }
      }
    );
    this.currentLevelSub = this.rewardsService.userLevelUpDetails$.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.currentLevelID = data["newLevelId"];
        }
      }
    );
    this.activeMenuSub = this.commonService.activeLeftMenu$.subscribe(
      (name) => {
        this.activeLeftMenuOption = name;
      }
    );

    this.updatelevelCountSub = this.rewardsService.updateLevelUpNotification$.subscribe(
      (count) => {
        this.levelUpNotificationCount = count;
      }
    );
    this.clearLevelupNotSub = this.rewardsService.clearLevelUpNotification$.subscribe(
      (flag) => {
        if (flag) {
          this.levelUpNotificationCount = 0;
        }
      }
    );

    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnInit() {}

  getCurrentUserLevel() {
    if (this.rewardsService.getUserCurrentLevelId()) {
      this.currentLevelID = this.rewardsService.getUserCurrentLevelId();
    }
  }

  openAccountComponent(viewname) {
    if (viewname === "rewards") {
      this.rewardsService.broadCastLevelupNotifications(true);
    }
    this.utility.openAccountComponent(viewname);
  }

  closeAccountOverlay() {
    if (this.activeView !== "deposit" && this.activeView !== "withdrawal") {
      this.utility.closeAccountComponent();
    }
  }

  closeAccountComponent(callingFrom?: string) {
    this.utility.closeAccountComponent();
  }

  gotoPromotion() {
    this.router.navigate([
      this.langCode + "/" + this.translationService.instant("url.promotions"),
    ]);
    this.closeAccountComponent();
  }

  gotoPayments() {
    this.router.navigate([
      this.langCode + "/" + this.translationService.instant("url.payments"),
    ]);
    this.closeAccountComponent();
  }

  navigateTo(path?) {
    if (path === "casino") {
      this.router.navigate([this.langCode + "/casino"]);
      this.closeAccountComponent();
      this.gamePlayService.clearRegistrationTimer();
    }
    if (path === "livespins") {
      this.router.navigate([this.langCode + "/livespins"]);
      this.closeAccountComponent();
    }
    if (path === "live-casino") {
      this.router.navigate([this.langCode + "/live-casino"]);
      this.closeAccountComponent();
    } else if (path === "login") {
      this.utility.initiateLogin();
    } else if (path) {
      this.router.navigate([this.langCode + "/" + path]);
    } else {
      this.router.navigate([this.langCode + "/casino"]);
    }
  }

  getUserKycLevelDetails() {
    this.userKycLevelSubs = this.commonService
      .getUserKycLevelDetails()
      .subscribe((data) => {
        this.iskycEnabled = data["enableKyc"];
      });
  }

  initLiveChat() {
    this.liveChatService.onInitializeChat();
    this.closeAccountComponent();
  }

  doLogout() {
    this.activeMenuSub.unsubscribe();
    this.countryCodeSub.unsubscribe();
    this.commonService.doLogout();
    this.closeAccountComponent();
    if (this.transactionStatus) {
      this.transactionStatus.unsubscribe();
    }

    if (this.userKycLevelSubs) {
      this.userKycLevelSubs.unsubscribe();
    }
    this.updatelevelCountSub.unsubscribe();
    this.clearLevelupNotSub.unsubscribe();
  }

  openDeposit() {
    this.closeAccountComponent();
    this.paynplayCashierService.broadCastInitiateDeposit({
      callingFrom: "accountMenu",
    });
  }

  openWithdraw() {
    this.closeAccountComponent();
    this.paynplayCashierService.broadCastInitiateWithdraw();
  }

  clearRewardNotifications() {
    if (this.levelUpNotificationCount) {
      this.levelUpNotificationCount = 0;
    }
    this.rewardsService.broadCastClearLevelupNotifications(true);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }
}

import { Component, OnDestroy, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import {
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  NavigationEnd,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Services
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

@Component({
  selector: "app-left-navigation",
  templateUrl: "./left-navigation.component.html",
  styleUrls: ["./left-navigation.component.scss"],
})
export class LeftNavigationComponent implements OnDestroy {
  loginCompleteSubscription: Subscription;
  isLoggedIn: boolean = false;
  userProfileSubscription: Subscription;
  username: string;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  userBalanceSubscription: Subscription;
  userTotalBalance: number = 0;
  langCode: string;
  langCodeSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  lobbyLeftMenuListConfig: any;
  activePage: string;
  activeLeftPageSub: Subscription;
  activeLeftMenuOption: string;
  isLoading: boolean = false;
  isbalanceLoading: boolean = true;
  activeLeftMenuSub: Subscription;
  countryCode;
  countryCodeSub: Subscription;
  UserFlowTypes: typeof UserFlowTypes;
  levelUpNotificationCount: number;
  clearLevelupNotSub: Subscription;
  updatelevelCountSub: Subscription;
  userJurisdiction: string;
  userJurisdictionSub: Subscription;

  hungarianStyle: boolean = false;

  @HostListener("window:resize") onResize() {
    this.setGameActivePage();
  }

  constructor(
    private commonService: CommonService,
    private registrationService: RegistrationService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private router: Router,
    public utility: UtilityService,
    private rewardsService: RewardsService,
    public liveChatService: LiveChatService,
    public gamePlayService: GamePlayService
  ) {
    this.UserFlowTypes = UserFlowTypes;
    if (this.commonService.getCountryCode()) {
      this.countryCode = this.commonService.getCountryCode();
    }
    this.countryCodeSub = this.commonService.updateCountryCode$.subscribe(
      (countryCode) => {
        this.countryCode = countryCode;
      }
    );
    
    this.observeActivePage();
    
    this.langCode = this.utility.getLangCode();
    if (this.langCode === "hu-hu") {
      this.hungarianStyle = true;
    }

    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
        if (this.langCode === "hu-hu") {
          this.hungarianStyle = true;
        }
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        this.userTotalBalance = 0;
        this.currencySymbol = undefined;
        this.isbalanceLoading = true;
      }
    );
    this.userProfileSubscription = this.userDetailsService.userProfileUpdated$.subscribe(
      (data) => {
        const userProfileData = this.userDetailsService.getUserProfileDetails();
        if (userProfileData) {
          this.username = userProfileData["firstName"];
        }
      }
    );
    /**Here we are using BehaviorSubject on initialization also v'll get default value..
     * we differentiate subject & BehaviorSubject with "Sb" at end of Event Name.
     */
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
      this.isbalanceLoading = false;
    }
    this.userBalanceSubscription = this.userDetailsService.userBalanceUpdated$.subscribe(
      (balanceUpdateMessage) => {
        if (balanceUpdateMessage == "UserBalanceUpdated") {
          this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
          this.isbalanceLoading = false;
        }
      }
    );
    this.activeLeftMenuSub = this.commonService.activeLeftMenu$.subscribe(
      (name) => {
        this.activeLeftMenuOption = name;
      }
    );

    this.updatelevelCountSub = this.rewardsService.updateLevelUpNotification$.subscribe(
      (count) => {
        this.levelUpNotificationCount = count;
      }
    );
    this.clearLevelupNotSub = this.rewardsService.clearLevelUpNotification$.subscribe(
      (flag) => {
        if (flag) {
          this.levelUpNotificationCount = 0;
        }
      }
    );
    this.userJurisdiction = this.commonService.getUserJurisdiction();
    this.userJurisdictionSub = this.commonService.userJurisdictionEvent$.subscribe(
      (jurisdiction) => {
        this.userJurisdiction = jurisdiction;
      }
    );
  }

  observeActivePage(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        /**Condition to hide banner where ever it not required
         * comes here...
         */
        const previousPage = this.activePage;

        const tree: UrlTree = this.router.parseUrl(event.url);
        const urlSegmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const urlSegments: UrlSegment[] = urlSegmentGroup ? urlSegmentGroup.segments : [];

        if (urlSegments && urlSegments[1] && urlSegments[1].path) {
          this.activePage = this.utility.getActiveLobby(urlSegments[1].path);
        } else {
          this.activePage = "";
        }

        // Update balance after leaving game view
        if (this.activePage !== "game" && previousPage === "game") {
          this.commonService.getUserBalanceByPockets();
        }
        
      }
    });
  }

  setGameActivePage() {
    if (
      this.utility.getDecodedCurrentPath().split("/")[2] ===
      this.translationService.instant("url.game")
    ) {
      this.activePage = this.utility.getActiveLobby(
        this.utility.getDecodedCurrentPath().split("/")[2]
      );
    }
  }

  ngOnInit() {
    this.getLobbyMenuList();
    this.setGameActivePage();
  }

  doLogout() {
    this.commonService.doLogout();
    this.updatelevelCountSub.unsubscribe();
    this.clearLevelupNotSub.unsubscribe();
  }

  openAccountMenuList() {
    this.commonService.broadCastActiveAcountMenu("menuOptions");
  }

  openAccountComponent(viewname) {
    this.utility.openAccountComponent(viewname);
  }

  initiateDeposit() {
    this.utility.initiateDeposit();
  }
  /**
   * All the left menu navigation all handled in below
   * callback function
   */

  navigateTo(path?) {
    if (this.commonService.getActiveAccountMenu()) {
      this.commonService.broadCastActiveAcountMenu("");
    }
    this.utility.updateActiveLeftMenu();
    if (path === "casino" && this.activePage === "game") {
      this.gamePlayService.broadCastGameWindowMinimized(true);
      this.gamePlayService.clearRegistrationTimer();
      this.router.navigate([this.langCode + "/casino"]);
    } else if (path === "rewards") {
      this.rewardsService.broadCastLevelupNotifications(true);
      this.commonService.broadCastActiveAcountMenu(path);
    } else if (path === "login") {
      this.utility.initiateLogin();
    } else if (path) {
      this.router.navigate([
        this.langCode + "/" + this.translationService.instant("url." + path),
      ]);
    } else {
      if (!this.utility.isUserLoggedIn() && !this.utility.isPnpFlow()) {
        this.router.navigate([this.langCode]);
      } else {
        this.commonService.broadCastUpdateActiveLobby({ lobby: "casino" });
        this.router.navigate([this.langCode + "/casino"]);
      }
      this.gamePlayService.clearRegistrationTimer();
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  openRegistrationPopup() {
    this.registrationService.openRegistrationHanlder();
  }

  /**
   * Below function helps us to get left menu navigation options
   * If we want to add any new navigation link into left menu simply
   * we have to first configure under /configurations/menulist.config/hir.json
   */
  getLobbyMenuList() {
    this.lobbyLeftMenuListConfig = this.utility.getBrandBasedConfig(
      "leftMenuConfig"
    );
  }

  initLiveChat() {
    this.liveChatService.onInitializeChat();
  }

  clearRewardNotifications() {
    if (this.levelUpNotificationCount) {
      this.levelUpNotificationCount = 0;
    }
    this.rewardsService.broadCastClearLevelupNotifications(true);
  }

  ngOnDestroy() {
    this.activeLeftMenuSub.unsubscribe();
    this.loginCompleteSubscription.add(this.countryCodeSub);
    this.loginCompleteSubscription.add(this.activeLeftPageSub);
    this.loginCompleteSubscription.add(this.currencySymbolSubscription);
    this.loginCompleteSubscription.add(this.userBalanceSubscription);
    this.loginCompleteSubscription.add(this.userProfileSubscription);
    this.loginCompleteSubscription.add(this.langCodeSubscription);
    this.loginCompleteSubscription.add(this.logOutCompleteSubscription);
    this.loginCompleteSubscription.add(this.userJurisdictionSub);
    this.loginCompleteSubscription.unsubscribe();
  }
}

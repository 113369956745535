import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { Subscription } from "rxjs";

// Configurations
import {
  marketLocaleCurrencyMappingConfigurations,
  avialableCountriesConfigurations,
  currencyMappingConfigurations,
} from "src/app/configurations/main.configurations";

// Enums
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";

// Libraries
import * as _ from "underscore";

// Models
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";

// Services
import { TranslationService } from "src/app/modules/shared/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Pipe({ 
  name: "currencyformat" 
})
export class CurrencyFormatPipe implements PipeTransform {
  // Strings
  currencySymbol: string = "";
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Arrays
  avialableCountries: string[] = [];

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private decimalPipe: DecimalPipe,
    private utility: UtilityService
  ) {
    this.avialableCountries = avialableCountriesConfigurations;

    this.languageCode = this.utility.getLangCode();

    this.isLoggedIn = this.utility.isUserLoggedIn();

    /*
      Here we receive both default currency(if we don't have user currency like
      before logged In state) & user currency
    */
    this.subscriptions = [
      this.userDetailsService.currencySymbolSb$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.translationService.langCode$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];
  }

  transform(
    value: string | number,
    currencySymbol: string,
    isdecimalNeeded: boolean = true
  ): string {
    let isCountryExist: boolean = false;
    let countryCode: string = "";

    // Objects
    let marketLocaleCurrencyMappingClone: {
      [key: string]: CurrencyConfigurations;
    } = marketLocaleCurrencyMappingConfigurations;

    let currencyMapConfigClone: {
      [key: string]: CurrencyConfigurations;
    } = currencyMappingConfigurations;

    let data: CurrencyConfigurations;

    let languageCodeFromURL: string = this.utility
      .getDecodedCurrentPath()
      .split("/")[1];

    if (
      languageCodeFromURL &&
      languageCodeFromURL !== this.languageCode &&
      _.contains(supportedLanguagesList(), languageCodeFromURL)
    ) {
      this.languageCode = languageCodeFromURL;
    }

    if (this.userDetailsService.userProfileDetails) {
      countryCode = this.userDetailsService.userProfileDetails.country;
    } else {
      countryCode = this.commonService.getCountryCode();
    }
    if (!this.isLoggedIn) {
      data = marketLocaleCurrencyMappingClone.hasOwnProperty(this.languageCode)
        ? marketLocaleCurrencyMappingClone[this.languageCode]
        : undefined;
    } else {
      data = currencyMapConfigClone.hasOwnProperty(countryCode)
        ? currencyMapConfigClone[countryCode]
        : undefined;
    }

    if (data && currencySymbol) {
      data.currencySymbol = CurrencySymbol[currencySymbol];
    } else if (data) {
      data.currencySymbol =
        CurrencySymbol[this.userDetailsService.getCurrencySymbol()];
    }

    isCountryExist =
      this.avialableCountries.indexOf(countryCode) >= 0 ? true : false;

    if (isdecimalNeeded) {
      value = this.decimalPipe.transform(value, "1.2-2");
    }

    if (
      (isCountryExist || !this.isLoggedIn) &&
      data &&
      data.position === CurrencyPosition.before
    ) {
      return `${data.currencySymbol}${data.isSpaceRequired ? " " : ""}${value}`;
    } else if (
      (isCountryExist || !this.isLoggedIn) &&
      data &&
      data.position === CurrencyPosition.after
    ) {
      return `${value}${data.isSpaceRequired ? " " : ""}${data.currencySymbol}`;
    } else if (data) {
      return `${data.currencySymbol}${data.isSpaceRequired ? " " : ""}${value}`;
    } else {
      return `${this.currencySymbol} ${value}`;
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}

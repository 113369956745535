import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  HostListener,
  EventEmitter,
  Component,
  OnInit,
  Output,
} from "@angular/core";

// Configurations
import {
  quickDepositDefaultValuesConfigurations,
  avialableCountriesConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import { CustomValidators } from "src/app/utility/custom-validator";

declare let encryptData: any;

@Component({
  selector: "app-quick-deposit",
  templateUrl: "./quick-deposit.component.html",
  styleUrls: ["./quick-deposit.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuickDepositComponent implements OnInit {
  piqObject = {};
  balanceDetails;
  currencySymbolSub: Subscription;
  currencySymbol;
  getUserUsedAccountSubs: Subscription;
  selectedPaymentMethod;
  cashierDetailsForm = this.fb.group({
    amount: [0, [this.customValidators.minValueNumber(50, 1000, "")]],
    cvv: ["", this.customValidators.exactNumberMatch(3)],
    accountId: ["", this.customValidators.required],
  });
  cashierAttributesForm = this.fb.group({
    successUrl: [""],
    failureUrl: [""],
    pendingUrl: [""],
    cancelUrl: [""],
    labelId: [""],
    productId: [""],
    ipAddr: [""],
    bnsCode: [""],
    type: ["QuickDeposit"],
  });
  transactionStatusIntervalCounter = 0;
  transactionStatusInterval;
  transactionStatusData;
  showScreen = "payment";
  redirectionTimer = 0;
  transactionStatusSubs: Subscription;
  makePaymentSubs: Subscription;
  isLoading = false;
  depositProgress = false;
  windowType;
  DEFAULT_MIN_PAYMENT_AMOUNT = 0;
  getTokenAjaxPaymentSub;
  @Output() noUsedMethodsAvailable: EventEmitter<any> = new EventEmitter();
  qdAmountInput: any;
  currencyPosition;
  userProfileSubscription: Subscription;
  redirectionOutput: any;
  currencySymbolSubscription: Subscription;
  qd3DPopup = false;

  @HostListener("window:message", ["$event"]) onMessage(e) {
    if (e && e.data && e.data.message === "deposit-transactionStatus") {
      this.getUserCurrentTransactionstatus(
        sessionStorage.getItem("_tid"),
        atob(sessionStorage.getItem("_tam"))
      );
    }
  }

  currencyCodeSub: Subscription;
  currencyCode: string;
  constructor(
    private userDetailsService: UserDetailsService,
    private cashierService: CashierService,
    private commonService: CommonService,
    private customValidators: CustomValidators,
    private gtmService: GtmService,
    public utils: UtilityService,
    private fb: FormBuilder,
    private emitterService: EmitterService,
    private utility: UtilityService
  ) {
    this.userProfileSubscription = this.userDetailsService.userProfileUpdated$.subscribe(
      (data) => {
        if (this.userDetailsService.userCurrencyDetails) {
          this.currencyPosition = this.getCurrencyPosition();
        }
      }
    );
    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (code) => {
        this.currencyCode = code;
      }
    );
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );
    if (this.userDetailsService.userCurrencyDetails) {
      this.currencyPosition = this.getCurrencyPosition();
    }
    this.emitterService.depositSuccessful$.subscribe((data) => {
      this.updateUserDetails();
    });
  }

  getCurrencyPosition() {
    let isCountryExist;
    const data = this.userDetailsService.userCurrencyDetails;
    if (this.userDetailsService.userProfileDetails) {
      data["countryCode"] = this.userDetailsService.userProfileDetails[
        "country"
      ];
      isCountryExist =
      avialableCountriesConfigurations.indexOf(data["countryCode"]) >= 0 ? true : false;
    }
    if (isCountryExist && data && data["position"] === "before") {
      return "before";
    } else if (isCountryExist && data && data["position"] === "after") {
      return "after";
    } else {
      return "before";
    }
  }

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  getWindowType() {
    const ww = document.body.clientWidth;
    if (ww <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  setQuickDepositdefaultValue() {
    this.DEFAULT_MIN_PAYMENT_AMOUNT =
    quickDepositDefaultValuesConfigurations["default_" + this.currencySymbol];
  }

  ngOnInit() {
    this.getWindowType();
    this.currencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (currencySymbol) => {
        this.currencySymbol = currencySymbol;
        if (this.currencySymbol) {
          this.setQuickDepositdefaultValue();
        }
      }
    );

    this.piqObject["method"] = "Deposit";
    this.updateUserDetails();
  }

  onHoverBottomRibbon() {
    this.utility.gameplayFooterMouseOver(true);
  }
  onMouseLeaveBottomRibbon() {
    this.utility.gameplayFooterMouseOver(false);
  }

  updateUserDetails() {
    const profileDetails = this.userDetailsService.getUserProfileDetails();
    this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
    this.isLoading = true;
    if (!_.isEmpty(profileDetails) && !_.isEmpty(this.balanceDetails)) {
      this.piqObject["userId"] = profileDetails.playerID;
      this.getCashierToken();
    } else {
      const getTokenObservable = this.cashierService.getToken({
        token: "cashier",
      });
      const getProfileDataObservable = this.commonService.getProfileBalanceCurrency();
      const getUserbalanceBypockets = this.commonService.getUserBalanceByPockets();
      Promise.all([
        getTokenObservable,
        getProfileDataObservable,
        getUserbalanceBypockets,
      ]).then((results) => {
        const profileData: any = results[1];
        if (
          profileData &&
          profileData.profile &&
          profileData.profile.playerID &&
          results[2]
        ) {
          this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
          this.piqObject["userId"] = profileData.profile.playerID;
          const tokenData = results[0];
          this.parseTokenData(tokenData);
        }
      });
    }
  }

  getCashierToken() {
    Promise.resolve(this.cashierService.getToken({ token: "cashier" })).then(
      (tokenDetails: any) => {
        if (
          tokenDetails &&
          tokenDetails.status === "SUCCESS" &&
          tokenDetails.token &&
          tokenDetails.token !== ""
        ) {
          this.parseTokenData(tokenDetails);
        }
      },
      (usedAccountError) => {
        console.log(usedAccountError);
      }
    );
  }

  parseTokenData(tokenDetails) {
    if (
      tokenDetails.status === "SUCCESS" &&
      tokenDetails.token &&
      tokenDetails.token !== ""
    ) {
      this.piqObject["sessionId"] = tokenDetails.token;
      this.piqObject["merchantId"] = environment.paymentIqMID;
      this.piqObject["sessionId"] = tokenDetails.token;
      this.cashierAttributesForm.controls.successUrl.setValue(
        tokenDetails.cashierCallbackUrl
      );
      this.cashierAttributesForm.controls.failureUrl.setValue(
        tokenDetails.cashierCallbackUrl
      );
      this.cashierAttributesForm.controls.pendingUrl.setValue(
        tokenDetails.cashierCallbackUrl
      );
      this.cashierAttributesForm.controls.cancelUrl.setValue(
        tokenDetails.cashierCallbackUrl
      );
      this.cashierAttributesForm.controls.labelId.setValue(
        tokenDetails.labelId
      );
      this.cashierAttributesForm.controls.productId.setValue(
        tokenDetails.productId
      );
      this.cashierAttributesForm.controls.ipAddr.setValue(tokenDetails.ipAddr);
      this.getUserUsedAccount();
    } else {
      this.selectedPaymentMethod = undefined;
    }
  }

  getUserUsedAccount() {
    this.getUserUsedAccountSubs = this.cashierService
      .getUserPIQMethods(_.clone(this.piqObject))
      .subscribe(
        (paymentMethods) => {
          this.isLoading = false;
          // select the first used card method
          if (
            paymentMethods &&
            paymentMethods["success"] &&
            paymentMethods["methods"]
          ) {
            const isCreditcardMethod = _.find(
              paymentMethods["methods"],
              (account) => {
                if (account.providerType === "CREDITCARD") {
                  return true;
                }
              }
            );
            if (
              isCreditcardMethod &&
              isCreditcardMethod.accounts &&
              isCreditcardMethod.accounts.length > 0
            ) {
              this.emitterService.broadCastNoCCCardsAvailable(false);
              this.selectedPaymentMethod = isCreditcardMethod.accounts[0];

              this.setInputSize();

              this.cashierDetailsForm.controls.amount.setValue(
                this.DEFAULT_MIN_PAYMENT_AMOUNT
              );
              if (isCreditcardMethod.limit) {
                const minLimit = isCreditcardMethod.limit.min;
                const maxLimit = isCreditcardMethod.limit.max;
                if (minLimit && maxLimit) {
                  this.cashierDetailsForm.controls.amount.setValidators([
                    this.customValidators.minValueNumber(
                      minLimit,
                      maxLimit,
                      ""
                    ),
                  ]);
                }
              }
              this.cashierDetailsForm.controls.accountId.setValue(
                this.selectedPaymentMethod.accountId
              );
            } else {
              this.emitterService.broadCastNoCCCardsAvailable(true);
              this.noUsedMethodsAvailable.emit(true);
              this.selectedPaymentMethod = undefined;
              this.openMainCashier();
            }
          } else {
            this.noUsedMethodsAvailable.emit(true);
            this.selectedPaymentMethod = undefined;
            this.openMainCashier();
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  openMainCashier() {
    if (this.windowType === "mobile") {
      this.utils.openAccountComponent("deposit");
    }
  }

  makeQuickDeposit() {
    this.depositProgress = true;
    Promise.resolve(this.cashierService.getToken({ token: "cashier" })).then(
      (tokenDetails: any) => {
        if (
          tokenDetails &&
          tokenDetails.status === "SUCCESS" &&
          tokenDetails.token &&
          tokenDetails.token !== ""
        ) {
          this.piqObject["sessionId"] = tokenDetails.token;
          this.piqObject["merchantId"] = environment.paymentIqMID;
          this.piqObject["sessionId"] = tokenDetails.token;
          this.cashierAttributesForm.controls.successUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.failureUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.pendingUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.cancelUrl.setValue(
            tokenDetails.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.labelId.setValue(
            tokenDetails.labelId
          );
          this.cashierAttributesForm.controls.productId.setValue(
            tokenDetails.productId
          );
          this.cashierAttributesForm.controls.ipAddr.setValue(
            tokenDetails.ipAddr
          );
          const requestObj = this.processPaymentRequest();
          this.makePaymentSubs = this.cashierService
            .makePayment(requestObj)
            .subscribe((cashierResp) => {
              const txnId = cashierResp.txRefId.substr(
                cashierResp.txRefId.indexOf("A") + 1
              );
              const txnRefId = cashierResp.txRefId;
              sessionStorage.setItem("_userId", this.piqObject["userId"]);
              sessionStorage.setItem("_sessionId", this.piqObject["sessionId"]);
              sessionStorage.setItem("_tid", txnId);
              sessionStorage.setItem("_trid", btoa(txnRefId));
              sessionStorage.setItem("_tam", btoa(requestObj["amount"]));

              if (cashierResp && cashierResp.success) {
                if (
                  cashierResp.success &&
                  cashierResp.txState &&
                  cashierResp.txState !== "WAITING_INPUT"
                ) {
                  this.getUserCurrentTransactionstatus(
                    txnId,
                    requestObj["amount"]
                  );
                } else if (
                  cashierResp.success &&
                  cashierResp.txState &&
                  cashierResp.txState === "WAITING_INPUT"
                ) {
                  this.redirectionOutput = cashierResp.redirectOutput;
                  const contentAndForm = this.cashierService.getIframe(
                    cashierResp.redirectOutput
                  );
                  const $content = contentAndForm.content;
                  const $form = contentAndForm.form;
                  if ($content || $form) {
                    this.openQuickDeposit3dPopup();
                    setTimeout(() => {
                      $("#redirectionDiv").css(
                        "height",
                        100 + this.redirectionOutput.height
                      );
                      $("#redirectionDiv").html($content);
                      const $iframe = $("#loaderIframe");
                      $iframe.ready(() => {
                        $iframe.contents().find("body").length > 0
                          ? $iframe.contents().find("body").append($form)
                          : $iframe.append($form);
                        if (
                          !cashierResp.redirectOutput.html &&
                          ($("#loaderIframe").contents().find("#proxy_redirect")
                            .length ||
                            $("#loaderIframe").find("#proxy_redirect").length)
                        ) {
                          if (
                            $("#loaderIframe")
                              .contents()
                              .find("#proxy_redirect").length > 0
                          ) {
                            $("#loaderIframe")
                              .contents()
                              .find("#proxy_redirect")
                              .submit();
                          } else {
                            $("#loaderIframe").find("#proxy_redirect").submit();
                          }
                        }
                      });
                    });
                  }
                }
              } else {
                this.showTransactionStatus("failure", undefined);
              }
            });
        } else {
          this.showTransactionStatus("failure", undefined);
        }
      },
      (usedAccountError) => {
        this.showTransactionStatus("failure", undefined);
      }
    );
  }

  getUserCurrentTransactionstatus(txId, amount) {
    const requestObj = {
      sessionId: this.piqObject["sessionId"],
      userId: this.piqObject["userId"],
      merchantId: environment.paymentIqMID,
      txRefId: txId,
    };
    this.transactionStatusSubs = this.cashierService
      .getPIQTransactionStatus(requestObj)
      .subscribe(
        (response) => {
          if (
            response &&
            response.statusCode === "SUCCESS" &&
            response.txState === "SUCCESSFUL"
          ) {
            this.commonService.getUserBalanceByPockets().then();
            this.showTransactionStatus("Success", amount);
          } else if (response && response["txState"] === "PROCESSING") {
            this.transactionStatusInterval = setInterval(() => {
              if (this.transactionStatusIntervalCounter <= 4) {
                this.transactionStatusIntervalCounter++;
                this.getUserCurrentTransactionstatus(txId, amount);
              } else {
                this.showTransactionStatus("failure", undefined);
              }
            }, 2000);
          } else {
            this.showTransactionStatus("failure", undefined);
          }
        },
        (errorResponse) => {
          this.showTransactionStatus("failure", undefined);
        }
      );
  }

  showTransactionStatus(status, successAmount) {
    clearInterval(this.transactionStatusInterval);
    this.transactionStatusIntervalCounter = 0;
    this.closeQuickDeposit3dPopup();
    this.transactionStatusData = {
      status,
      successAmount,
    };
    this.depositProgress = false;
    this.showScreen = "transaction";
    let count = 6;
    const timerInstance = setInterval(() => {
      this.redirectionTimer = --count;
      if (this.redirectionTimer === 0) {
        clearInterval(timerInstance);
        this.showScreen = "payment";
        this.cashierDetailsForm.controls.amount.setValue(
          this.DEFAULT_MIN_PAYMENT_AMOUNT
        );
        this.cashierDetailsForm.controls.cvv.setValue("");
      }
    }, 1000);

    if (status === "Success") {
      this.updateGtmEvent({
        txAmount: successAmount,
        merchantTxId: sessionStorage.getItem("_tid"),
      });
      this.emitterService.broadCastDepositSuccesful(status);
    }
    sessionStorage.removeItem("_userId");
    sessionStorage.removeItem("_sessionId");
    sessionStorage.removeItem("_tid");
    sessionStorage.removeItem("_trid");
    sessionStorage.removeItem("_tam");
  }

  processPaymentRequest() {
    let dynamicFormValues = {};
    const selectPaymentMethods = this.selectedPaymentMethod.type;
    const requestObject = {
      paymentMethod: "deposit",
      methodType: "process",
      merchantId: this.piqObject["merchantId"]
        ? this.piqObject["merchantId"]
        : environment.paymentIqMID,
      sessionId: this.piqObject["sessionId"],
      userId: this.piqObject["userId"],
      attributes: this.utils.formControlToParams(
        this.cashierAttributesForm,
        {}
      ),
      provider: selectPaymentMethods.toLowerCase(),
      amount: this.cashierDetailsForm.get("amount").value,
    };
    if (this.selectedPaymentMethod.service) {
      requestObject["service"] = this.selectedPaymentMethod.service;
    }
    dynamicFormValues = this.utils.formControlToParams(
      this.cashierDetailsForm,
      {}
    );
    if (selectPaymentMethods === "CREDITCARD") {
      if (dynamicFormValues["cvv"]) {
        dynamicFormValues["encCvv"] = encryptData(dynamicFormValues["cvv"]);
        delete dynamicFormValues["cvv"];
      }
    }
    _.each(dynamicFormValues, (value, key) => {
      if (value === "") {
        delete dynamicFormValues[key];
      }
    });
    return { ...requestObject, ...dynamicFormValues };
  }

  cancel3DTransaction() {
    this.closeQuickDeposit3dPopup();
    this.getUserCurrentTransactionstatus(
      sessionStorage.getItem("_tid"),
      atob(sessionStorage.getItem("_tam"))
    );
  }

  openQuickDeposit3dPopup() {
    this.qd3DPopup = true;
    this.utils.addClassToAppBody("overflow-hidden");
  }

  closeQuickDeposit3dPopup() {
    this.qd3DPopup = false;
    this.utils.removeClassFromAppBody("overflow-hidden");
  }

  getButtonDisabled(form) {
    if (form && form.valid) {
      return false;
    } else {
      return true;
    }
  }

  tryAgain() {
    this.showScreen = "payment";
  }

  setInputSize() {
    if (document.getElementById("qdAmountInput")) {
      let qdAmountInput = document.getElementById("qdAmountInput");
      qdAmountInput["size"] = qdAmountInput["value"].length
        ? qdAmountInput["value"].length
        : 4;
    }
  }

  updateGtmEvent(responseData) {
    if (
      this.userDetailsService.getUserProfileDetails() &&
      responseData["merchantTxId"]
    ) {
      this.gtmService.gtmDataLayerEvent({
        event: "user_interaction",
        event_type: "deposit",
        currency: this.currencyCode,
        txAmount: responseData["txAmount"],
        transactioRefId: responseData["merchantTxId"],
        userid: this.userDetailsService.getUserProfileDetails().playerID,
        event_context: "subsequent",
      });
    }
  }

  ngOnDestroy() {
    this.currencySymbolSubscription.unsubscribe();
    this.userProfileSubscription.unsubscribe();
    if (this.currencySymbolSub) {
      this.currencySymbolSub.unsubscribe();
    }
    if (this.getUserUsedAccountSubs) {
      this.getUserUsedAccountSubs.unsubscribe();
    }
    if (this.transactionStatusSubs) {
      this.transactionStatusSubs.unsubscribe();
    }
    if (this.makePaymentSubs) {
      this.makePaymentSubs.unsubscribe();
    }
    if (this.getTokenAjaxPaymentSub) {
      this.getTokenAjaxPaymentSub.unsubscribe();
    }
  }
}

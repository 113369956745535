import { TitleCasePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

// Models
import { UserData } from "src/app/modules/user/models/user-data.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { UtilityService } from "src/app/utility/utility.service";

// Utilities
import {
  appendInitializeLiveChatNoScript,
  appendInitializeLiveChatScript,
} from "src/app/modules/chat/utilities/live-chat-script.utilities";

@Injectable({
  providedIn: "root",
})
export class LiveChatService {
  // Objects
  profileDetails: UserData;

  // Subscriptions
  userDataSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private titleCasePipe: TitleCasePipe
  ) {
    this.subscription = this.commonService.loginComplete$.subscribe(() => {
      this.onLoadLiveChat();
    });
  }

  // -----------------------------------------------------------------
  // Get Methods
  getTitleCase(value: string): string {
    return this.titleCasePipe.transform(value);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoadLiveChat(): void {
    if (!document.getElementById("lc")) {
      appendInitializeLiveChatNoScript();

      appendInitializeLiveChatScript();
    }
  }

  onInitializeChat(): void {
    this.onLoadLiveChat();

    if (window.LiveChatWidget && document.getElementById("lc")) {
      if (this.utilityService.isUserLoggedIn()) {
        this.onGetUserProfileData();
      }

      window.LiveChatWidget.call("maximize");
    }
  }

  onGetUserProfileData(): void {
    if (this.userDetailsService.getUserProfileDetails()) {
      this.profileDetails = this.userDetailsService.getUserProfileDetails();

      this.onSetUserDetails(this.profileDetails);
    } else {
      this.userDataSubscription = this.commonService
        .getUserData()
        .subscribe((userData: UserData) => {
          this.profileDetails = userData;

          this.onSetUserDetails(this.profileDetails);
        });
    }
  }

  onSetUserDetails(profileData: UserData): void {
    /*
      Please lines of code is used to set customer name & customer email Id
      which will be auto populated & visisble directly when customer
      opens chat
    */
    if (profileData.email && profileData.firstName) {
      window.LiveChatWidget.call("set_customer_email", profileData.email);

      window.LiveChatWidget.call(
        "set_customer_name",
        `${this.getTitleCase(profileData.firstName)} ${this.getTitleCase(
          profileData.lastName
        )}`
      );
    }

    if (profileData) {
      this.onSetSessionData(profileData);
    }
  }

  /*
    This set of code is used to create a user session on live chat,
    which help used in identifying  user session along
    with back on live chat back office
  */
  onSetSessionData(profileData: UserData): void {
    window.LiveChatWidget.call("set_session_variables", {
      name: `${this.getTitleCase(profileData.firstName)} ${this.getTitleCase(
        profileData.lastName
      )}`,
      emailID: profileData.email,
      ecrID: profileData.playerID,
      currentLevel: this.rewardsService.getUserCurrentLevelId(),
      loginStatus: "logged In",
    });
  }

  onUpdateSessionData(): void {
    if (window.LiveChatWidget && document.getElementById("lc")) {
      window.LiveChatWidget.call("update_session_variables", {
        loginStatus: "logged out",
      });
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.userDataSubscription) this.userDataSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}

export const appendInitializeLiveChatScript = (): void => {
  let script: HTMLScriptElement = document.createElement("script");

  script.id = "lc";

  script.textContent =
    'window.__lc = window.__lc || {}; window.__lc.license = 12701157;;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You cant use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))';

  document.body.appendChild(script);
};

export const appendInitializeLiveChatNoScript = (): void => {
  let noScript: HTMLElement = document.createElement("noscript");

  let appendTagLink: HTMLAnchorElement = document.createElement("a");
  appendTagLink.href = "https://www.livechatinc.com/chat-with/12701157/";
  appendTagLink.rel = "nofollow";
  appendTagLink.textContent = "Chat with us powered by ";

  let appendHrefTagLink: HTMLAnchorElement = document.createElement("a");
  appendHrefTagLink.href = "https://www.livechatinc.com/?welcome";
  appendHrefTagLink.rel = "noopener nofollow";
  appendHrefTagLink.target = "_blank";
  appendHrefTagLink.textContent = "LiveChat";

  noScript.append(appendTagLink);
  noScript.append(appendHrefTagLink);

  document.body.appendChild(noScript);
};

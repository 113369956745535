import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// Services
import { CommonService } from "src/app/modules/shared/services/common.service";
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable()
export class AccountService {
  constructor(
    private commonService: CommonService,
    private mainService: MainService
  ) {}

  getTransactionHistory(requestObj): Observable<any> {
    return this.mainService.getTransactionHistory(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCashierTransactionHistory(requestObj): Observable<any> {
    return this.mainService.getCashierTransactionHistory(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  setResponsibleGamingLimits(requestObj): Observable<any> {
    return this.mainService.setResponsibleGamingLimits(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getResponsibleGamingLimits(type): Observable<any> {
    return this.mainService.getResponsibleGamingLimits(type).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getSubscriptionPreference() {
    return this.mainService.getSubscriptionPreference().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  updateSubscriptionPreference(requestObj) {
    return this.mainService.updateSubscriptionPreference(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  suspendAccount(requestObj): Observable<any> {
    return this.mainService.suspendAccount(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return error;
      })
    );
  }
  getBetTransactions(requestObj): Observable<any> {
    return this.mainService.getBetTransactions(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  changePasswordHandler(requestData): Observable<any> {
    return this.mainService.changePasswordHanlder(requestData).pipe(
      map((responseData) => {
        return responseData;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  getActiveBonusDetails(): Observable<any> {
    return this.mainService.getActiveBonusDetails().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  dropBonus(requestObj): Observable<any> {
    return this.mainService.dropBonus(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  upLoadDocuments(requestObj): Observable<any> {
    return this.mainService.upLoadDocuments(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  sendDocuments(requestData?): Observable<any> {
    return this.mainService.sendDocuments(requestData).pipe(
      map((response) => {
        if (response && response["documents"]) {
          let data = {
            addressStatus: response["documents"]["addressStatus"],
            ageStatus: response["douments"]["ageStatus"],
            identityStatus: response["documents"]["identityStatus"],
            cafStatus: response["documents"]["cafStatus"],
            sourceOfIncomeStatus: response["documents"]["sourceOfIncomeStatus"],
          };
          this.commonService.setUserAccountKycStatus(data);
        }
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getAccountVerficationStatus(): Observable<any> {
    return this.mainService.getAccountVerficationStatus().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  cancelDepositLimts(requestObj): Observable<any> {
    return this.mainService.cancelDepositLimits(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  cancelLimits(requestObj): Observable<any> {
    return this.mainService.cancelLimits(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  setNetDepositLimits(requestObj): Observable<any> {
    return this.mainService.setNetDepositLimits(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getNetDepositLimits(): Observable<any> {
    return this.mainService.getNetDepositLimits().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  cancelNetDepositLimits(requestObj): Observable<any> {
    return this.mainService.cancelNetDepositLimits(requestObj).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  deleteNetDepositLimits(type): Observable<any> {
    return this.mainService.deleteNetDepositLimits(type).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}

import { environment } from "src/environments/environment";

export const supportedLanguagesList = (): string[] => {
  let languages: string[] = [
    "en-row",
    "nb-no",
    "fi-fi",
    "en-ca",
    "en-nz",
    "de-eu",
    "en-ie",
    ...(environment.features.hungarian ? ["hu-hu"] : []),
    ...(environment.features.markets.estonia ? ["et-ee"] : []),
  ];

  return languages;
};

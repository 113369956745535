import { environment } from "src/environments/environment";

// Enums
import { Launch_Relax_No_Limit_City } from "src/app/models/configurations/enums/market/launch-relax-no-limit-city.enum";
import { Launch_Relax_Stake_Logic } from "src/app/models/configurations/enums/market/launch-relax-stake-logic.enum";
import { Launch_Relax_Fantasma } from "src/app/models/configurations/enums/market/launch-relax-fantasma.enum";
import { Launch_Relax_High_5 } from "src/app/models/configurations/enums/market/launch-relax-high-five.enum";
import { Launch_Relax_Hacksaw } from "src/app/models/configurations/enums/market/launch-relax-hacksaw.enum";
import { Launch_Relax_Skywind } from "src/app/models/configurations/enums/market/launch-relax-skywind.enum";
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { Launch_Micro_Gaming } from "src/app/models/configurations/enums/market/launch-micro-gaming.enum";
import { Launch_Thunderkick } from "src/app/models/configurations/enums/market/launch-thunder-kick.enum";
import { Launch_Push_Gaming } from "src/app/models/configurations/enums/market/launch-push-gaming.enum";
import { Launch_Live_Casino } from "src/app/models/configurations/enums/market/launch-live-casino.enum";
import { Launch_Quick_Spin } from "src/app/models/configurations/enums/market/launch-quick-spin.enum";
import { Launch_Ygg_Drasil } from "src/app/models/configurations/enums/market/launch-ygg-drasil.enum";
import { Launch_Is_of_tbet } from "src/app/models/configurations/enums/market/launch-is-of-tbet.enum";
import { Launch_Play_N_Go } from "src/app/models/configurations/enums/market/launch-play-n-go.enum";
import { Launch_Red_Tiger } from "src/app/models/configurations/enums/market/launch-red-tiger.enum";
import { Launch_Pragmatic } from "src/app/models/configurations/enums/market/launch-pragmatic.enum";
import { Launch_Betsoft } from "src/app/models/configurations/enums/market/launch-betsoft.enum";
import { Launch_Booming } from "src/app/models/configurations/enums/market/launch-booming.enum";
import { Launch_Playson } from "src/app/models/configurations/enums/market/launch-playson.enum";
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { Launch_Hub_88 } from "src/app/models/configurations/enums/market/launch-hub-88.enum";
import { Launch_Netent } from "src/app/models/configurations/enums/market/launch-netent.enum";
import { Launch_Slots } from "src/app/models/configurations/enums/market/launch-slots.enum";
import { CryptoCurrency } from "src/app/models/configurations/enums/crypto-currency.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";
import { Launch_Vivo } from "src/app/models/configurations/enums/market/launch-vivo.enum";
import { LaunchMessages } from "src/app/models/configurations/enums/launch-messages.enum";
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";
import { CountryCodes } from "src/app/models/configurations/enums/country-code.enum";
import { LaunchHub88 } from "src/app/models/configurations/enums/launch-hub-88.enum";
import { AccountView } from "src/app/models/configurations/enums/account-view.enum";
import { LaunchRelax } from "src/app/models/configurations/enums/launch-relax.enum";

// Models
import { LaunchGameUrlBasedOnSubProvidersPrefixConfigurations } from "src/app/models/configurations/general-configurations/launch-game-url-based-on-provider-configuration.model";
import { UserProfileRegisteredLanguageConfigurations } from "src/app/models/configurations/general-configurations/user-profile-registered-language-configuration.model";
import { LivefeedRouletteNumberColorsConfigurations } from "src/app/models/configurations/general-configurations/livefeed-roulette-number-colors-configuration.model";
import { SeoLanguageCodeUpdateConfigurations } from "src/app/models/configurations/general-configurations/seo-language-code-update-configuration.model";
import { LanguageToGameLaunchConfigurations } from "src/app/models/configurations/general-configurations/language-to-game-launch-configuration.model";
import { LaunchGameBasedOnMarketConfigurations } from "src/app/models/configurations/general-configurations/launch-game-market.model-configuration";
import { LocalRegilyLanguageConfigurations } from "src/app/models/configurations/general-configurations/local-regily-language-configuration.model";
import { LanguageToRegisterConfigurations } from "src/app/models/configurations/general-configurations/language-to-register-configuration.model";
import { CmsLocalLanguageConfigurations } from "src/app/models/configurations/general-configurations/cms-local-language-configuration.model";
import { LocalCMSLanguageConfigurations } from "src/app/models/configurations/general-configurations/local-cms-language-configuration.model";
import { CountryLanguageConfigurations } from "src/app/models/configurations/general-configurations/country-language-configuration.model";
import { LanguageToPIQConfigurations } from "src/app/models/configurations/general-configurations/language-to-piq-configuration.model";
import { HrefLanguageConfigurations } from "src/app/models/configurations/general-configurations/href-language-configuration.model";
import { IncomeSourceConfigurations } from "src/app/models/configurations/general-configurations/income-source-configuration.model";
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";
import { ZimplerLanguageToCountryConfigurations } from "src/app/models/configurations/general-configurations/zimpler-language-to-country-configuration.model copy";
import { WorldCountryCode } from "src/app/models/configurations/enums/localization/world-countries.enum";

/*
  Here we are using different code for indian english
  & canada as per our configuration in CMS iirespective of their 
  respective code
  For Indian English is "ei"
  For Canada English is 'cd'
  rw stands for rest of world English 

  Regily Language to CMS
*/
export const cmsToLocalLanguageConfigurations: CmsLocalLanguageConfigurations = {
  en: LanguageCodes["en-row"],
  at: LanguageCodes["de-eu"],
  cd: LanguageCodes["en-ca"],
  nz: LanguageCodes["en-nz"],
  fi: LanguageCodes["fi-fi"],
  no: LanguageCodes["nb-no"],
  ie: LanguageCodes["en-ie"],
  hu: LanguageCodes["hu-hu"],
  et: LanguageCodes["et-ee"]
};

/*
  CMS to Language Codes for Regily
*/
export const localToCmsLanguageConfigurations: LocalCMSLanguageConfigurations = {
  "en-row": LanguageCodes.en,
  "de-eu": LanguageCodes.at,
  "en-ca": LanguageCodes.cd,
  "en-nz": LanguageCodes.nz,
  "fi-fi": LanguageCodes.fi,
  "nb-no": LanguageCodes.no,
  "en-ie": LanguageCodes.ie,
  "hu-hu": LanguageCodes.hu,
  "et-ee": LanguageCodes.et
};

/*
  Special cofiguration for regily registration flow.
*/
export const localToRegilyLanguageConfigurations: LocalRegilyLanguageConfigurations = {
  "en-row": LanguageCodes.en,
  "de-eu": LanguageCodes.de,
  "en-ca": LanguageCodes.en,
  "en-nz": LanguageCodes.nz,
  "fi-fi": LanguageCodes.fi,
  "nb-no": LanguageCodes.no,
  "en-ie": LanguageCodes.ie,
  "hu-hu": LanguageCodes.hu,
  "et-ee": LanguageCodes.et
};

export const time24FormatConfigurations: string[] = [
  "en-row",
  "nb-no",
  "fi-fi",
  "de-eu",
  "en-ca",
  "en-nz",
  "en-ie",
  "hu-hu",
  "et-ee"
];

/*
  Special configuration for country ip based to our CMS support.

  Reference: https://www.science.co.il/language/Codes.php
  https://docs.oracle.com/cd/E13214_01/wli/docs92/xref/xqisocodes.html
*/
export const countryLanguageConfigurations: CountryLanguageConfigurations = {
  NO: LanguageCodes.no,
  FI: LanguageCodes.fi,
  NZ: LanguageCodes.nz,
  CA: LanguageCodes.cd,
  AT: LanguageCodes.at,
  IE: LanguageCodes.ie,
  HU: LanguageCodes.hu,
  EE: LanguageCodes.et
};

/*
  Special Configuartion hreflang based on regions
*/
export const hrefLanguageConfigurations: HrefLanguageConfigurations = {
  "en-row": LanguageCodes.en,
  "de-eu": LanguageCodes["de-eu"],
  "en-ca": LanguageCodes["en-ca"],
  "fi-fi": LanguageCodes["fi-fi"],
  "nb-no": LanguageCodes["nb-no"],
  "en-nz": LanguageCodes["en-nz"],
  "en-ie": LanguageCodes["en-ie"],
  "hu-hu": LanguageCodes["hu-hu"],
  "et-ee": LanguageCodes["et-ee"]
};

/**
 * On client request we started deciding user language based
 * on his country selection , not based on his locale(old logic)
 */
export const languageToRegisterConfigurations: LanguageToRegisterConfigurations = {
  CA: "en_CA",
  NZ: "en_NZ",
  FI: "fi_FI",
  NO: "no_NO",
  AT: "de_AT",
  IE: "en_IE",
  HU: "hu_HU",
  EE: "et_EE",
  others: "en_US",
};

/*
  Language Codes for User Profile Registered
*/
export const userProfileRegisteredLanguageConfigurations: UserProfileRegisteredLanguageConfigurations = {
  en_CA: LanguageCodes["en-ca"],
  en_NZ: LanguageCodes["en-nz"],
  fi_FI: LanguageCodes["fi-fi"],
  no_NO: LanguageCodes["nb-no"],
  de_AT: LanguageCodes["de-eu"],
  en_US: LanguageCodes["en-row"],
  en_IE: LanguageCodes["en-ie"],
  hu_HU: LanguageCodes["hu-hu"], //this may also need to be updated from the CMS,
  et_EE: LanguageCodes["et-ee"]
};

/**
  Basically in below language to Piq config
  For Austria site language code de-eu & for PIQ we have
  to pass de_At (austria) same as we pass for pragmatic BO,
  but on client request we have adjusted the config to pass
  de_DE(german language code) to make their life easy in
  PIQ configuration setup.  (linked:PROD-236)
  
  Language To PIQ -> Payment IQ
*/
export const languageToPIQConfigurations: LanguageToPIQConfigurations = {
  "en-row": "en_US",
  "de-eu": "de_DE",
  "en-ca": "en_CA",
  "en-nz": "en_NZ",
  "fi-fi": "fi_FI",
  "nb-no": "no_NO",
  "en-ie": "en_IE",
  "hu-hu": "hu_HU",
  "et-ee": "et_EE"
};

/*
  Language To Game Launch
*/
export const languageToGameLaunchConfigurations: LanguageToGameLaunchConfigurations = {
  "en-row": LanguageCodes.en,
  "en-ca": LanguageCodes.cd,
  "en-nz": LanguageCodes.nz,
  "fi-fi": LanguageCodes.fi,
  "nb-no": LanguageCodes.no,
  "de-eu": LanguageCodes.de,
  "en-ie": LanguageCodes.ie,
  "hu-hu": LanguageCodes.hu,
  "et-ee": LanguageCodes.et
};

/*
  Account View List
*/
export const accountViewConfigurations: string[] = [
  AccountView.my_transacts_history,
  AccountView.confirm_identity,
  AccountView.change_password,
  AccountView.update_profile,
  AccountView.bet_history,
  AccountView.preferences,
  AccountView.withdrawal,
  AccountView.time_out,
  AccountView.deposit,
  AccountView.rewards,
  AccountView.limits,
  AccountView.bonus,
];

/*
  Max Supported File Size
*/
export const maxSupportedFileSizeInBytesConfigurations: number = 3145728;

/*
  Income Source
*/
export const incomeSourceListConfigurations: IncomeSourceConfigurations[] = [
  {
    value: "savingsFromSalary",
    value_text: "sourceoffundDropDownText.salary_wage",
    description: "sourceoffundDropDownText.salary_wage_des",
  },
  {
    value: "companyProfits",
    value_text: "sourceoffundDropDownText.business_profits",
    description: "sourceoffundDropDownText.business_profits_des",
  },
  {
    value: "salesOfSharesOrOtherInvestments",
    value_text: "sourceoffundDropDownText.sale_of_shares",
    description: "sourceoffundDropDownText.sale_of_shares_des",
  },
  {
    value: "saleOfProperty",
    value_text: "sourceoffundDropDownText.sale_of_property",
    description: "sourceoffundDropDownText.sale_of_property_des",
  },
  {
    value: "companySale",
    value_text: "sourceoffundDropDownText.sale_assets_company_sale",
    description: "sourceoffundDropDownText.sale_assets_company_sale_des",
  },
  {
    value: "dividentPayment",
    value_text: "sourceoffundDropDownText.dividentPayment",
    description: "sourceoffundDropDownText.dividentPayment_des",
  },
  {
    value: "fixedDepositSavings",
    value_text: "sourceoffundDropDownText.fixedDepositSavings",
    description: "sourceoffundDropDownText.fixedDepositSavings_des",
  },
  {
    value: "inheritance",
    value_text: "sourceoffundDropDownText.Inheritance_Gift",
    description: "sourceoffundDropDownText.Inheritance_Gift_des",
  },
  {
    value: "loan",
    value_text: "sourceoffundDropDownText.loan",
    description: "sourceoffundDropDownText.loan_des",
  },
  {
    value: "gift",
    value_text: "sourceoffundDropDownText.gift",
    description: "sourceoffundDropDownTextgift",
  },
  {
    value: "other",
    value_text: "sourceoffundDropDownText.other",
    description: "sourceoffundDropDownText.other_des",
  },
];

/*
  Crypto Currencies that are currently Supported

  https://coinmarketcap.com/all/views/all/

  https://finance.yahoo.com/cryptocurrencies/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAANkjVq8ZFLyEltZ72_UW2pr9xW75GFH4x29iDvDozbrbFz4lIQHMeglwyoRA7SRHlwi4Xe3af9-uGgUoG3WSETfzaHabBzARhDeGOSj70am2vIf6ptaJtsgywf7CKgwR-TnqzL-FTyS_WuAO3LOXHOejKE7uP7ofLV1C5yOjbeza
*/
export const cryptoCurrencyListConfigurations: string[] = [
  CryptoCurrency.BCH,
  CryptoCurrency.BTC,
  CryptoCurrency.ETH,
  CryptoCurrency.LTC,
];

/*
  Live Feed Roulette Number Colours
*/
export const liveFeedRouletteNumberColorsConfigurations: LivefeedRouletteNumberColorsConfigurations = {
  black: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
  red: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
  green: [0],
};

/*
  Quick Deposit Default Values
*/
export const quickDepositDefaultValuesConfigurations: {
  [key: string]: number;
} = {
  default_kr: 1000,
  default_KR: 1500,
  "default_€": 100,
  default_$: 100,
  default_Ft: 100,
};

/*
  This mapping is required for logout user's to show currency as per
  there country Locality

  NOTE: Used only for logout Users.
*/
export const currencyMappingConfigurations: {
  [key: string]: CurrencyConfigurations;
} = {
  CA: {
    currencyCode: WorldCurrencyCode.CAD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  FI: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  NO: {
    currencyCode: WorldCurrencyCode.NOK,
    currencySymbol: CurrencySymbol.kr,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  AT: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  NZ: {
    currencyCode: WorldCurrencyCode.NZD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  IE: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  HU: {
    currencyCode: WorldCurrencyCode.HUF,
    currencySymbol: CurrencySymbol.Ft,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  EE: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  }
};

/*
  Available Countries
*/
export const avialableCountriesConfigurations: string[] = [
  CountryCodes.CA,
  CountryCodes.FI,
  CountryCodes.NO,
  CountryCodes.NZ,
  CountryCodes.AT,
  CountryCodes.IE,
  CountryCodes.HU,
  CountryCodes.EE
];

/*
 this config used to display deposit button on Banner if user has lessar than config balance else visible Play button
*/
export const bannerCTAConfigurations: {
  [key: string]: number;
} = {
  EUR: 5,
  CAD: 5,
  NZD: 5,
  USD: 5,
  NOK: 50,
  HUF: 50,
};

export const userMinimumRGLimitCheckConfigurations: {
  [key: string]: number;
} = {
  kr: 100,
  $: 10,
  C$: 10,
  "€": 10,
  Ft: 100,
};

/*
  SEO Language Code Update
*/
export const seoLanguageCodeUpdateConfigurations: SeoLanguageCodeUpdateConfigurations = {
  "en-row": LanguageCodes.en,
  "de-eu": LanguageCodes.at,
  "en-ca": LanguageCodes.en,
  "en-nz": LanguageCodes.en,
  "fi-fi": LanguageCodes.fi,
  "nb-no": LanguageCodes.nb,
  "en-ie": LanguageCodes.ie,
  "hu-hu": LanguageCodes.hu,
  "et-ee": LanguageCodes.et
};

export const liveProviderCheckListPreferredOrderConfigurations: string[] = [
  "hub88_evolutiongaming",
  "pragmaticplay",
  "hub88_ezugi",
  "netent",
  "vivo",
];

export const mixPanelEventsConfigurations: {
  [key: string]: string;
} = {
  clicked_Provider_Filter: "Clicked Live Casino Provider Filter",
  clicked_Provider_Lobby: "Clicked Provider Lobby",
  clicked_Game_Category: "Clicked Game Category",
  initiated_Search: "Initiated Search",
  launched_game: "Launched Game",
};

export const excludeLanguageOptionsConfigurations: string[] = ["de-eu"];

export const marketLocaleCurrencyMappingConfigurations: {
  [key: string]: CurrencyConfigurations;
} = {
  "en-ca": {
    currencyCode: WorldCurrencyCode.CAD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "fi-fi": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "nb-no": {
    currencyCode: WorldCurrencyCode.NOK,
    currencySymbol: CurrencySymbol.kr,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "de-eu": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "en-nz": {
    currencyCode: WorldCurrencyCode.NZD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "en-ie": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "en-row": {
    currencyCode: environment.features.currency.enRow$ ? WorldCurrencyCode.USD : WorldCurrencyCode.EUR,
    currencySymbol: environment.features.currency.enRow$ ? CurrencySymbol.$ : CurrencySymbol["€"],
    position: environment.features.currency.enRow$ ? CurrencyPosition.before : CurrencyPosition.after,
    isSpaceRequired: !environment.features.currency.enRow$,
  },
  "hu-hu": {
    currencyCode: WorldCurrencyCode.HUF,
    currencySymbol: CurrencySymbol.Ft,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "et-ee": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
};

export const seoAlternativeLanguagesConfigurations: string[] = [
  "en-row",
  "nb-no",
  "fi-fi",
  "en-nz",
  "en-ca",
  "en-ie",
  "hu-hu",
  "et-ee"
];

export const languageToPnpConfigurations: {
  [key: string]: string;
} = {
  "fi-fi": LanguageCodes.fi,
  "et-ee": LanguageCodes.et
};
/*
  This mapping is required for pt-br, es-sa locale
  For only below providers game launch URL we are addding lang Codes
*/
export const launchGameURlBasedOnSubProvidersPrefixConfigurations: LaunchGameUrlBasedOnSubProvidersPrefixConfigurations = {
  "hu-hu": {
    hub88: LaunchHub88.hu,
    relax: LaunchRelax.hu_HU,
    mgs: LaunchMessages.hu,
  },
};

export const launchGameURLBasedOnMarketConfigurations: {
  [key: string]: LaunchGameBasedOnMarketConfigurations;
} = {
  "hu-hu": {
    quickspin: Launch_Quick_Spin.hu,
    yggdrasil: Launch_Ygg_Drasil.hu,
    thunderkick: Launch_Thunderkick.hu,
    vivo: Launch_Vivo.hu,
    playngo: Launch_Play_N_Go.hu,

    relax_fantasma: Launch_Relax_Fantasma.hu,
    relax_hacksaw: Launch_Relax_Hacksaw.HU,
    relax_skywind: Launch_Relax_Skywind.HU,
    relax_high5: Launch_Relax_High_5.hu,
    relax_stakelogic: Launch_Relax_Stake_Logic.hu,
    betsoft: Launch_Betsoft.hu,
    pushgaming: Launch_Push_Gaming.hu,

    // Optional
    relax_nolimitcity: Launch_Relax_No_Limit_City.HU,
    isoftbet: Launch_Is_of_tbet.HU,
    redtiger: Launch_Red_Tiger.hu,

    pragmatic: Launch_Pragmatic.hu,
    livecasino: Launch_Live_Casino.hu,
    slots: Launch_Slots.hu,
    booming: Launch_Booming.hu,

    hub88: Launch_Hub_88.hu,
    playson: Launch_Playson.hu,
    netent: Launch_Netent.hu,
    microgaming: Launch_Micro_Gaming.hu,
  },
};

/**
 * Converter from language to country used for Zimpler
 */
export const zimplerLanguageToCountryConfigurations: ZimplerLanguageToCountryConfigurations = {
  "fi": WorldCountryCode.FI,
  "fi-fi": WorldCountryCode.FI,
  "et": WorldCountryCode.EE,
  "et-ee": WorldCountryCode.EE
};
